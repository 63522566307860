import React from 'react';
import ReactDOM from 'react-dom';
// import * as Sentry from "@sentry/react";
// import { Integrations } from '@sentry/tracing';
import App from './App';
import Store from './services/Store';

import 'assets/css/material-dashboard-react.css?v=1.10.0';
// Sentry.init({
//   dsn: 'https://4b68d8ccdbc44ea8a6d1dbd2a36c7ff4@o464537.ingest.sentry.io/6156281',
//   integrations: [new Integrations.BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 0,
// });
const rootElement = document.getElementById('root');

ReactDOM.render(
  <Store.Container>
    <App />
  </Store.Container>,
  rootElement,
);
