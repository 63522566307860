import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { auth } from "../services/firebase";


// Function that will be called to refresh authorization
const refreshAuthLogic = failedRequest => auth().currentUser.getIdToken(true).then((newToken) => {
    // console.log('refreshing tokenn');
    // console.log(newToken);
        localStorage.setItem('token', newToken);
        return Promise.resolve();
    });

// Instantiate the interceptor
createAuthRefreshInterceptor(axios, refreshAuthLogic, {statusCodes: [403, 401]});

// Obtain the fresh token each time the function is called
function getAccessToken(){
    // console.log('GETT');
    // console.log(localStorage.getItem('token'));
    return localStorage.getItem('token');
}

// Use interceptor to inject the token to requests
axios.interceptors.request.use(request => {
    request.headers['Authorization'] = `Bearer ${getAccessToken()}`;
    return request;
});

export default axios;