import React, { Component } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
// import { Link } from 'react-router-dom';
// to-do: the switch loses the track and only keeps the thumb, need to fix
// 'description' placeholder text is misaligned in the new code dialog box
// there seems to be styles overriding it
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';

import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CleaningServicesRoundedIcon from '@mui/icons-material/CleaningServicesRounded';
import DoneAllIcon from '@mui/icons-material/DoneAll';

import Store from '../services/Store';
import { withStyles } from '@mui/styles';
import axios from '../helpers/axios';
import EditCode from './codes/EditCode';
import ResolveReports from './codes/ResolveReports';

// import { XGrid, GridToolbar } from '@material-ui/x-grid';
var QRCode = require('qrcode');
import QuickFilteringGrid from '../components/QuickFilteringGrid';

import '../styles.css';

const styles = (theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  root: {
    display: 'flex',
  },
  details: {
    display: 'flex',
  },
  avatar: {
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
  },
  locationText: {
    paddingLeft: '15px',
  },
  buttonProperty: {
    position: 'absolute',
    top: '50%',
  },
  uiProgess: {
    position: 'fixed',
    zIndex: '1000',
    height: '31px',
    width: '31px',
    left: '50%',
    marginLeft: -75,
    top: '35%',
  },
  progess: {
    position: 'absolute',
  },
  uploadButton: {
    marginLeft: '8px',
    margin: theme.spacing(1),
  },
  customError: {
    color: 'red',
    fontSize: '0.8rem',
    marginTop: 10,
  },
  submitButton: {
    marginTop: '10px',
  },
});

class QrCodes extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      codes: [],
      uiLoading: true,
      modalOpen: false,
      newCodeDescription: '',
      buttonLoading: false,
      editCodeID: null,
      reviewCodeID: null,
      codeIdentifer: null,
    };

    this.columns = [
      {
        field: 'edit',
        headerName: 'Edit',
        renderCell: (params) => {
          let markAll = '';
          if (
            params.getValue(params.id, 'status') !== 'Clean' &&
            params.getValue(params.id, 'status') !== 'No Reports'
          ) {
            let codeIdentifer = params.getValue(params.id, 'identifier');
            markAll = (
              <Tooltip title="Resolve Issues">
                <IconButton
                  onClick={() =>
                    this.handleReviewReports(params.id, codeIdentifer)
                  }
                  size="large">
                  <DoneAllIcon />
                </IconButton>
              </Tooltip>
            );
          }
          return (
            <>
              <Tooltip title="Edit QR Code">
                <IconButton
                  onClick={() => this.handleEditCode(params.id)}
                  edge="end"
                  aria-label="edit"
                  style={{  margin: '0 auto' }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              {/* {markAll} */}
            </>
          );
        },
        width: 80,
        resizable: false,
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: 'id',
        headerName: 'IDS',
        hide: true,
      },
      {
        field: 'identifier',
        headerName: 'Identifier',
        width: 250,
      },
      {
        field: 'description',
        headerName: 'Description',
        width: 300,
      },
      /* Remove 'isActive' column => can activate from edit QR Code
      {
        field: 'isActive',
        headerName: 'Active',
        width: 100,
        align: 'center',
        sortable: false,
        type: 'boolean',
        renderCell: (params) => {
          let thisActive = params.getValue(params.id, 'isActive');
          return (
            <FormGroup>
              <Switch
                checked={
                  thisActive == true || thisActive == 'True' ? true : false
                }
                onChange={() => this.toggleActive(params.id, thisActive)}
              />
            </FormGroup>
          );
        },
      },
      */
      {
        field: 'status',
        headerName: 'No. of Issues',
        width: 150,
        renderCell: (params) => {
          if (
            params.getValue(params.id, 'status') !== 'Clean' &&
            params.getValue(params.id, 'status') !== 'No Reports'
          ) {
            return 'Reported';
          } else {
            // return 'No Reports';
            return 0;
          }
        },
      },
      {
        field: 'lastReportedAt',
        headerName: 'Last Report',
        width: 175,
        valueGetter: (params) => {
          return params.getValue(params.id, 'lastReportAt');
        },
        renderCell: (params) => {
          if (params.getValue(params.id, 'lastReportAt')) {
            return this.timeConverter(
              params.getValue(params.id, 'lastReportAt'),
            );
          }
        },
      },
      {
        field: 'preview',
        headerName: 'QR Page Link',
        width: 150,
        disableColumnMenu: true,
        renderCell: (params) => {
          var getUrl = window.location;
          let url =
            getUrl.protocol +
            '//' +
            getUrl.host +
            '/c/' +
            params.id +
            '?preview';
          return (
            <a target="_blank" href={url}>
              Preview
            </a>
          );
        },
      },
      {
        field: 'cleaner',
        headerName: 'Issues',
        width: 150,
        disableColumnMenu: true,
        renderCell: (params) => {
          var getUrl = window.location;
          let url = getUrl.protocol + '//' + getUrl.host + '/c/' + params.id;
          return (
            <a target="_blank" href={url}>
              Cleaner
            </a>
          );
        },
      },
    ];
  }

  addZero = (i) => {
    if (i < 10) {
      i = '0' + i;
    }
    return i;
  };
  timeConverter = (UNIX_timestamp) => {
    var a = new Date(UNIX_timestamp * 1000);
    var months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = this.addZero(a.getHours());
    var ampm = hour >= 12 ? 'pm' : 'am';
    hour = hour % 12;
    hour = hour ? hour : 12; // the hour '0' should be '12'
    var min = this.addZero(a.getMinutes());
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ampm;
    return time;
  };

  updateCode = (updateValues, updateCodeID = null) => {
    const { codes, editCodeID } = this.state;
    let updateCodes = [...codes];
    let index = -1;
    if (updateCodeID) {
      index = codes.findIndex((obj) => obj.codeID == updateCodeID);
    } else {
      // console.log('noid');
      index = codes.findIndex((obj) => obj.codeID == editCodeID);
    }
    if (typeof index === 'undefined' || typeof codes[index] === 'undefined') {
      updateCodes.push(updateValues);
    } else {
      let code = codes[index];
      for (var key of Object.keys(updateValues)) {
        code[key] = updateValues[key];
      }
      updateCodes[index] = code;
    }

    this.setState({ codes: [...updateCodes] });
  };
  setCodes = (team, authToken) => {
    // console.log('wtffff');
    // console.log(axios.interceptors);
    // axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
    axios
      .get(`/api/team/${team}/codes`)
      .then((response) => {
        this.setState({
          codes: response.data.codes,
          teamMembers: response.data.teamMembers,
          uiLoading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ errorMsg: 'Error in retrieving the data' });
      });
  };

  componentWillMount = () => {
    this._isMounted = true;
    const { store } = this.props;
    const authToken = store.get('user').accessToken;
    const currentTeam = store.get('currentTeam');
    this.setCodes(currentTeam, authToken);
    store.on('currentTeam').subscribe((currentTeam) => {
      if (this._isMounted) {
        this.setState({ uiLoading: true });
        this.setCodes(currentTeam, authToken);
      }
    });
  };
  componentWillUnmount() {
    if (this._isMounted) this._isMounted = false;
  }
  createNewCode = () => {
    const { store } = this.props;
    const { codes } = this.state;

    const authToken = store.get('user').accessToken;
    const currentTeam = store.get('currentTeam');
    axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
    const formRequest = {
      codeDescription: this.state.newCodeDescription,
    };
    axios
      .post(`/api/team/${currentTeam}/code`, formRequest)
      .then((teamResponse) => {
        let newCodes = [];
        codes.forEach((code) => {
          newCodes.push(code);
        });
        newCodes.push(teamResponse.data);
        this.setState({
          codes: newCodes,
          buttonLoading: false,
          newCodeDescription: '',
        });
        this.handleEditCode(teamResponse.data.id);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          buttonLoading: false,
        });
      });
    this.handleClose();
  };

  handleOpen = () => {
    this.setState({ modalOpen: true });
  };
  handleClose = () => {
    this.setState({ modalOpen: false });
  };
  handleEditClose = () => {
    this.setState({
      editCodeID: null,
      reviewCodeID: null,
      codeIdentifer: null,
    });
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleEditCode = (codeId) => {
    this.setState({
      editCodeID: codeId,
    });
  };

  handleReviewReports = (codeId, codeIdentifer) => {
    this.setState({
      reviewCodeID: codeId,
      codeIdentifer: codeIdentifer,
    });
  };

  toggleActive = (codeID, thisActive) => {
    const { store } = this.props;
    const authToken = store.get('user').accessToken;
    const currentTeam = store.get('currentTeam');
    var formData = new FormData();

    var newActive = true;
    // console.log(this.state.codes);

    const formRequest = {
      isActive: !thisActive,
    };
    window.this = this;
    axios
      .post(`/api/codes/${codeID}`, formRequest, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(
        function () {
          let updateCodes = [...this.state.codes];
          let index = this.state.codes.findIndex((obj) => obj.codeID == codeID);

          let code = this.state.codes[index];
          code['isActive'] = !thisActive;

          updateCodes[index] = code;
          this.setState({ codes: [...updateCodes] });
        }.bind(this, thisActive, codeID),
      );
  };
  render() {
    const { classes } = this.props;
    const { codes } = this.state;

    let modalStyle = {
      top: `45%`,
      left: `50%`,
      transform: `translate(-45%, -50%)`,
    };

    if (this.state.uiLoading === true) {
      return (
        <div className={classes.root}>
          <Header></Header>

          <main className={classes.content}>
            <div className={classes.toolbar} />
            {this.state.uiLoading && (
              <CircularProgress size={150} className={classes.uiProgess} />
            )}
          </main>
        </div>
      );
    } else {
      let renderEditCode = '';
      if (this.state.editCodeID !== null) {
        renderEditCode = (
          <EditCode
            teamMembers={this.state.teamMembers}
            handleEditClose={this.handleEditClose}
            updateCode={this.updateCode}
            codeID={this.state.editCodeID}
          />
        );
      }
      let renderReviewCode = '';
      if (this.state.reviewCodeID !== null) {
        renderReviewCode = (
          <ResolveReports
            handleEditClose={this.handleEditClose}
            updateCode={this.updateCode}
            codeIdentifier={this.state.codeIdentifer}
            codeID={this.state.reviewCodeID}
          />
        );
      }

      return (
        <div className={classes.root}>
          <Header></Header>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <Dialog
              fullWidth
              maxWidth="md"
              open={this.state.modalOpen}
              onClose={this.handleClose}
              aria-labelledby="max-width-dialog-title"
            >
              <DialogContent>
                <h2>New QR Code</h2>
                <h4>
                  <small>
                    This unique link will allow users to report items to your
                    team.
                  </small>
                </h4>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Description"
                      margin="dense"
                      name="newCodeDescription"
                      id="newCodeDescription"
                      variant="outlined"
                      value={this.state.newCodeDescription}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  {/* <Grid item xs={1}>
                    <IconButton
                      size="large"
                      onClick={this.createNewCode}
                      aria-label="new code"
                    >
                      <AddCircleIcon fontSize="large" color="primary" />
                    </IconButton>
                  </Grid> */}
                </Grid>
                  <DialogActions>
                    <Button
                      onClick={this.handleClose}
                      variant="outlined"
                      type="submit"
                      style={{ marginTop: '15px' }}
                    >
                      Close
                    </Button>
                    <Button
                      onClick={this.createNewCode}
                      variant="contained"
                      color="primary"
                      style={{ marginTop: '15px' }}
                    >
                      Create
                    </Button>
                  </DialogActions>
              </DialogContent>
            </Dialog>
            <QuickFilteringGrid
              filter={{
                id: 1,
                columnField: 'isActive',
                operatorValue: 'is',
                value: 'true',
              }}
              rows={this.state.codes}
              columns={this.columns}
              addItem={
                <Tooltip title="Add New QR Code">
                  <IconButton
                    onClick={this.handleOpen}
                    aria-label="add new code"
                  >
                    <AddCircleIcon fontSize="large" color="primary" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Footer></Footer>
          </main>
          {renderEditCode}
          {renderReviewCode}
        </div>
      );
    }
  }
}

export default Store.withStore(withStyles(styles)(QrCodes));
