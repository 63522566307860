import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

const config = require('./config');

firebase.initializeApp(config);

export const auth = firebase.auth;
export const storage = firebase.storage;
// export const db = firebase.database();
