import React, { Component, useRef } from 'react';
// import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Store from '../../services/Store';
import { withStyles } from '@mui/styles';
import axios from '../../helpers/axios';
import AppBar from '@mui/material/AppBar';
// to-do: figure out why colors turned back to magenta for this page specifically after changing the following import
// to-do: fix - border colors should not be magenta, dropdown styling
import {
  Button,
  Grid,
  TextField,
  IconButton,
  Divider,
  FormControlLabel,
  Link,
  ListItemSecondaryAction,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputBase from '@mui/material/InputBase';
import ButtonGroup from '@mui/material/ButtonGroup';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Checkbox from '@mui/material/Checkbox';

import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import smoothscroll from 'smoothscroll-polyfill';
import Container from '@mui/material/Container';
import LocationSearchInput from '../../components/LocationSearchInput';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import Geocode from 'react-geocode';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Avatar from '@mui/material/Avatar';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import PhotoModal from '../../components/PhotoModal';
import ConfirmDialog from '../../components/ConfirmDialog';
import Resizer from 'react-image-file-resizer';
import piexif from 'piexifjs';
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: '100px',
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: '#fff',
  },
  button: {
    textTransform: 'none',
  },
  logButton: {
    textTransform: 'none',
    marginBottom: 50,
  },
  formControl: {
    width: '100%',
    padding: theme.spacing(1),
  },
  selectControl: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBotton: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  uiProgess: {
    position: 'fixed',
    zIndex: '1000',
    height: '31px',
    width: '31px',
    left: '50%',
    marginLeft: -75,
    top: '35%',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  img: {
    maxWidth: 400,
    overflow: 'hidden',
    width: '100%',
  },
  alignCenter: {
    textAlign: 'center',
  },
});

class Cleaner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      team: [],
      gpsCoordinates: {},
      gpsCoordinateString: '',
      comments: '',
      qrQuantity: 1,
      baseRoute: 'codes',
      stage: 0,
      qrCode: {},
      uiLoading: true,
      buttonLoading: false,
      photoPreview: 'Attach a Photo',
      artGallery: [],
      activeStep: 0,
      reportID: null,
      confirmOpen: false,
      confirmModalTitle: '',
      photoPreviewAfter: 'Attach an After Photo',
    };
    this.artEnd = React.createRef();
  }

  componentDidMount = () => {
    const { store, qrCodeID } = this.props;
    const authToken = store.get('user').accessToken;
    let openRoute = `/api/codes/${qrCodeID}/open_reports`;
    if (qrCodeID == 'NONE') {
      openRoute = `/api/team/${this.props.teamSlug}/open_reports`;
    }
    if (this.props.qrCodeSlug) {
      openRoute = `/api/codes/${this.props.qrCodeSlug}/open_reports?slug=true`;
    }
    axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
    axios
      .get(openRoute)
      .then((response) => {
        if (response.data.length == 0 || response.data.reports.length == 0) {
          if (qrCodeID == 'NONE') {
            window.location.replace('/t/' + this.props.teamSlug + '?log');
          } else {
            if (this.props.qrCodeSlug) {
              window.location.replace('/s/' + this.props.qrCodeSlug + '?log');
            } else {
              window.location.replace('/c/' + qrCodeID + '?log');
            }
          }
        }

        this.setState({
          reports: response.data.reports,
          qrCode: response.data.qrCode,
          team: response.data.qrCode.team,
          uiLoading: false,
          modalOpen: true,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          uiLoading: false,
        });
      });
  };
  resolveReport = (reportId) => {
    const { store, qrCodeID } = this.props;
    const { reports } = this.state;
    // console.log(qrCodeID);

    const authToken = store.get('user').accessToken;
    const currentTeam = store.get('currentTeam');
    axios.defaults.headers.common = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${authToken}`,
    };

    var formData = new FormData();

    formData.append('reportIDs', [reportId]);

    if (this.state.photoStringAfter) {
      formData.append('rubbishAfterPhoto', this.state.photoStringAfter);
    }
    this.setState({ showQuickLoad: true });
    window.this = this;
    axios
      .post(`/api/code/${qrCodeID}/resolve_reports`, formData)
      .then((teamResponse) => {
        let newReports = reports.filter((item) => item.id !== reportId);
        window.this.setState({
          reports: newReports,
          photoStringAfter: '',
          photoPreviewAfter: 'Attach an After Photo',
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          buttonLoading: false,
        });
      });
  };

  resolveAllReports = () => {
    // if (confirm("Are you sure you'd like to resolve all reports?")){
    const { store, qrCodeID } = this.props;
    const { reports } = this.state;

    const authToken = store.get('user').accessToken;
    const currentTeam = store.get('currentTeam');

    axios.defaults.headers.common = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${authToken}`,
    };
    let reportIds = [];
    reports.forEach((r) => {
      reportIds.push(r.id);
    });
    // const formRequest = {
    //   reportIds: reportIds
    // };
    var formData = new FormData();

    formData.append('reportIDs', reportIds);

    if (this.state.photoStringAfter) {
      formData.append('rubbishAfterPhoto', this.state.photoStringAfter);
    }
    this.setState({ showQuickLoad: true });
    window.this = this;
    axios
      .post(`/api/code/${qrCodeID}/resolve_reports`, formData)
      .then((teamResponse) => {
        window.this.setState({
          reports: [],
          photoStringAfter: '',
          photoPreviewAfter: 'Attach an After Photo',
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          buttonLoading: false,
        });
      });
  };
  triggerPhoto = (e) => {
    e.preventDefault();
    document.getElementById('rubbishPhoto').click();
  };
  scrollToBottom = (e) => {
    smoothscroll.polyfill();
    // console.log(document.getElementById("scroll-to-view"));
    window.document
      .getElementById('scroll-to-view')
      .scrollIntoView({ behavior: 'smooth' });
  };
  showPhotoPreview = (e) => {
    const { photoPreview } = this.state;
    const file = document.querySelector('#rubbishPhoto').files[0];
    if (file) {
      var reader = new FileReader();
      window.this = this;
      reader.onload = function (e) {
        let photoPreview = (
          <img className="photo-preview" src={e.target.result} />
        );
        window.this.setState({
          photoPreview: photoPreview,
        });
      };

      reader.readAsDataURL(file); // convert to base64 string
    }
  };
  handleCheck = (event) => {
    if (event.target.checked) {
      this.setState({ [event.target.name]: true });
    } else {
      this.setState({ [event.target.name]: false });
    }
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  onCheck = (event) => {
    if (event.target.checked) {
      this.setState({ [event.target.name]: true });
    } else {
      this.setState({ [event.target.name]: false });
    }
  };

  handleNext = () => {
    this.setState({ activeStep: this.state.activeStep + 1 });
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  handleStepChange = (step) => {
    this.setState({ activeStep: step });
  };
  setConfirmOpen = (setConfirm = false) => {
    this.setState({
      confirmOpen: setConfirm,
      confirmModalTitle: '',
      confirmationFunction: undefined,
    });
  };
  addZero = (i) => {
    if (i < 10) {
      i = '0' + i;
    }
    return i;
  };
  triggerPhotoAfter = (e) => {
    e.preventDefault();
    document.getElementById('rubbishAfterPhoto').click();
  };
  showPhotoPreviewAfter = (e) => {
    const { photoPreviewAfter } = this.state;
    const file = document.querySelector('#rubbishAfterPhoto').files[0];
    if (file) {
      try {
        Resizer.imageFileResizer(
          e.target.files[0],
          800,
          800,
          'JPEG',
          100,
          0,
          (uri) => {
            let photoPreviewAfter = <img className="photo-preview" src={uri} />;
            this.setState({ photoPreviewAfter: photoPreviewAfter });
          },
          'base64',
          200,
          200,
        );
        Resizer.imageFileResizer(
          e.target.files[0],
          800,
          800,
          'JPEG',
          100,
          0,
          (uri) => {
            var reader = new FileReader();
            // reader.setState = this.setState;
            //read exif from original file, and apply to newly resized file
            try {
              reader.readAsDataURL(e.target.files[0]);
              reader.onload = () => {
                var reader2 = new FileReader();
                reader2.readAsDataURL(uri);
                reader2.onload = () => {
                  try {
                    var exifObj = piexif.load(reader.result);
                    if (exifObj['0th'][274] === 6) {
                      //idk but this fixes the wierd rotations, 6 tells the computer to rotate it, 0 says dont
                      exifObj['0th'][274] = 0;
                    }
                    var exifStr = piexif.dump(exifObj);

                    let newFile = piexif.insert(exifStr, reader2.result);
                    var exifObj2 = piexif.load(newFile);
                    this.setState({
                      photoStringAfter: dataURLtoFile(newFile, 'image-upload'),
                    });
                  } catch (err) {
                    console.log(err);
                    this.setState({
                      photoStringAfter: uri,
                    });
                  }
                };
                reader2.onerror = function (error) {
                  console.log('Error: ', error);
                };
              };
              reader.onerror = function (error) {
                console.log('Error: ', error);
              };
            } catch (err) {
              console.log(err);
              this.setState({
                photoStringAfter: uri,
              });
            }
          },
          'file',
          200,
          200,
        );
      } catch (err) {
        console.log(err);
        this.setState({
          photoPreviewAfter: 'Error Attaching Photo',
          photoStringAfter: null,
        });
      }
    }
  };
  timeConverter = (UNIX_timestamp) => {
    var a = new Date(UNIX_timestamp * 1000);
    var months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = this.addZero(a.getHours());
    var ampm = hour >= 12 ? 'pm' : 'am';
    hour = hour % 12;
    hour = hour ? hour : 12; // the hour '0' should be '12'
    var min = this.addZero(a.getMinutes());
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ampm;
    return time;
  };
  render() {
    const { classes, qrCodeID } = this.props;
    const { uiLoading, activeStep } = this.state;

    let content;

    if (this.state.uiLoading === true) {
      return (
        <div className={classes.root}>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            {this.state.uiLoading && (
              <CircularProgress size={150} className={classes.uiProgess} />
            )}
          </main>
        </div>
      );
    } else {
      let toolbar = '';
      if (this.props.authenticated && this.props.match.params.teamSlug) {
        toolbar = (
          <>
            <Backdrop className={classes.backDrop} open={this.state.open} />
            <SpeedDial
              ariaLabel="SpeedDial tooltip example"
              className={classes.speedDial}
              hidden={this.state.hidden}
              icon={<SpeedDialIcon />}
              onClose={this.handleClose}
              onOpen={this.handleOpen}
              open={this.state.open}
            >
              {actions.map((action) => (
                <SpeedDialAction
                  classes={{ staticTooltipLabel: classes.staticTooltipLabel }}
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  tooltipOpen
                  onClick={action.onClick || this.handleClose}
                />
              ))}
            </SpeedDial>
          </>
        );
      }

      let resolveAllButton = '';
      if (this.props.qrCodeID !== 'NONE') {
        resolveAllButton = (
          <>
            <Button
              onClick={() => {
                this.setState({
                  confirmationFunction: () => {
                    this.resolveAllReports();
                  },
                  confirmModalTitle: 'Resolve All Reports?',
                  confirmOpen: true,
                });
              }}
              fullWidth
              color="primary"
              variant="contained"
            >
              Resolve All
            </Button>
          </>
        );
      }

      return <>
        <img src="/images/newcloud.png" className="background-clouds" />
        <Container maxWidth="sm">
          <div id="submitForm" className={classes.root}>
            <Grid
              justifyContent="center"
              alignItems="center"
              spacing={1}
              container
            >
              <Grid item md={12} xs={12}>
                <h4 className="text-center mt-5">My Assigned Issues</h4>
                <h5 className="text-center">
                  {this.state.qrCode.identifier || ''} <br />
                  {this.state.qrCode.description || ''} <br />
                  {this.state.qrCode.location || ''}
                </h5>
              </Grid>
              <Grid item md={12} xs={12}>
                <List>
                  {this.state.reports &&
                    Object.keys(this.state.reports).map((key) => {
                      let r = this.state.reports[key];
                      let rubbishPhoto = <DeleteOutlineIcon />;
                      if (r.rubbishPhotoURL) {
                        rubbishPhoto = (
                          <PhotoModal rubbishPhotoURL={r.rubbishPhotoURL} />
                        );
                      }
                      let secondRow =
                        'Reported at ' + this.timeConverter(r.reportedAt);
                      if (r.lat && r.long) {
                        secondRow = (
                          <>
                            {secondRow}{' '}
                            <a
                              target="_blank"
                              href={
                                'http://www.google.com/maps/place/' +
                                r.lat +
                                ',' +
                                r.long
                              }
                            >
                              Map
                            </a>
                          </>
                        );
                      }

                      return (
                        <div key={key}>
                          <ListItem key={key}>
                            <ListItemAvatar>
                              <Avatar
                                variant="rounded"
                                className={classes.rounded}
                              >
                                {rubbishPhoto}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={r.rubbishType + ': ' + r.comments}
                              secondary={secondRow}
                            />
                            <ListItemSecondaryAction>
                              <IconButton
                                onClick={() => {
                                  this.setState({
                                    confirmationFunction: () => {
                                      this.resolveReport(r.id);
                                    },
                                    confirmModalTitle:
                                      'Mark Report as Resolved?',
                                    confirmOpen: true,
                                  });
                                }}
                                edge="end"
                                aria-label="delete"
                                size="large">
                                <DoneOutlineIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                          <Divider variant="inset" component="li" />
                        </div>
                      );
                    })}
                </List>
              </Grid>
              <Grid item xs={6}>
                {resolveAllButton}
              </Grid>
              <Grid item xs={6}>
                <Button variant="contained" fullWidth href="?log">
                  Log an Issue
                </Button>
              </Grid>
            </Grid>

            <ConfirmDialog
              title={this.state.confirmModalTitle}
              open={this.state.confirmOpen}
              setOpen={this.setConfirmOpen}
              onConfirm={() => this.state.confirmationFunction()}
            >
              <FormControl variant="outlined" className={classes.formControl}>
                <Button
                  className={classes.button}
                  variant="outlined"
                  size="large"
                  endIcon={<CameraAltIcon />}
                  onClick={this.triggerPhotoAfter}
                >
                  {this.state.photoPreviewAfter}
                </Button>
                <input
                  onChange={this.showPhotoPreviewAfter}
                  id="rubbishAfterPhoto"
                  name="rubbishAfterPhoto"
                  type="file"
                  accept="image/*"
                />
              </FormControl>
            </ConfirmDialog>
            <AppBar position="fixed" className={classes.appBar}>
              <div className="fixed-bottom footer">
                <Divider />
                <div className="row">
                  <div className="footer-img col-6 text-right">
                    <a href="https://www.rubbish.love">
                      <img src="https://qr.rubbish.love/images/powered_by_rubbish.png" />
                    </a>
                  </div>
                  <div className="footer-img col-6">
                    {this.state.team.footerPhotoURL &&
                      this.state.team.footerPhotoURL.length > 0 && (
                        <a href={this.state.team.footerPhotoLink || ''}>
                          <img src={this.state.team.footerPhotoURL || ''} />
                        </a>
                      )}
                  </div>
                </div>
                {toolbar}
              </div>
            </AppBar>
          </div>
        </Container>
      </>;
    }
  }
}

export default Store.withStore(withStyles(styles)(Cleaner));
