import { StoreEffect } from './Store';

export let withEffects: StoreEffect = (store) => {
  store.on('currentTeam').subscribe((currentTeam) => {
    // console.log('Current team changed to', currentTeam);
    window.localStorage.setItem('currentTeam', currentTeam);
  });
  store.on('currentTeamDisplayName').subscribe((currentTeamDisplayName) => {
    // console.log('Current team name changed to', currentTeamDisplayName);
    window.localStorage.setItem(
      'currentTeamDisplayName',
      currentTeamDisplayName,
    );
  });
  return store;
};
