import React, { Component, useRef } from 'react';

// import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Store from '../../services/Store';
import { withStyles } from '@mui/styles';
import axios from '../../helpers/axios';
import AppBar from '@mui/material/AppBar';
// report an issue button changed back to pink after updating the following import:
import {
  Button,
  Grid,
  TextField,
  IconButton,
  Divider,
  FormControlLabel,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputBase from '@mui/material/InputBase';
import ButtonGroup from '@mui/material/ButtonGroup';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Checkbox from '@mui/material/Checkbox';

import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import smoothscroll from 'smoothscroll-polyfill';
import Container from '@mui/material/Container';
import LocationSearchInput from '../../components/LocationSearchInput';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import Geocode from 'react-geocode';
Geocode.setApiKey('AIzaSyDPxht5Pz1aXxzuT5Gieomx6e1O6zOxR4s');
import Resizer from 'react-image-file-resizer';
import piexif from 'piexifjs';
import ReactHtmlParser from 'react-html-parser';
import { storage } from '../../services/firebase';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: '100px',
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: '#fff',
  },
  button: {
    textTransform: 'none',
    padding: '7px 12px',
  },
  formControl: {
    width: '100%',
    padding: theme.spacing(1),
  },
  selectControl: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBotton: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  selectLabel: {
    paddingLeft: theme.spacing(1),
  },
  uiProgess: {
    position: 'fixed',
    zIndex: '1000',
    height: '31px',
    width: '31px',
    left: '50%',
    marginLeft: -75,
    top: '35%',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  img: {
    maxWidth: 400,
    overflow: 'hidden',
    width: '100%',
  },
  alignCenter: {
    textAlign: 'center',
  },
});
function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}
function toDataURL(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
}

const MIXED_CATEGORY_RUBBISH_TYPE_ID = 'mF7Zj8D4LpQwKXt9QhTv';
const MIXED_CATEGORY_RUBBISH_TYPE_NAME = 'mixed';
const MIXED_CATEGORY_RUBBISH_TYPE_LABEL = 'Use AI to Auto-Categorize 🤖';

class QrCode extends Component {
  constructor(props) {
    super(props);

    this.state = {
      team: {},
      gpsCoordinates: {},
      gpsCoordinateString: '',
      comments: '',
      qrQuantity: 1,
      stage: this.props.logIssue ? 2 : 0,
      logReport: this.props.logIssue ? true : false,
      reportText: this.props.logIssue ? 'Log' : 'Report',
      uiLoading: true,
      buttonLoading: false,
      photoPreview: 'Attach a Photo',
      photoString: '',
      photoStringAfter: '',
      photoPreviewAfter: 'Attach an After Photo',
      pageHeader: '',
      pageSubHeader: '',
      artGallery: [],
      activeStep: 0,
      reportID: null,
      enableSubmit: true,
    };
    this.artEnd = React.createRef();
  }

  setCode = (qrCodeId, authToken, useSlug = false) => {
    axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
    axios
      .get(`/api/public/code/${qrCodeId}?slug=${useSlug}`)
      .then((response) => {
        const {team, code} = response.data;
        const {enableAICategorization, pageHeader, pageSubHeader} = (team ?? {});
        const {artGallery, skipLanding} = (code ?? {});

        if (skipLanding === true) {
          // console.log('skip landing');
          this.setState({ stage: 2 });
        }

        this.setState({
          code: code,
          team: team,
          pageHeader: pageHeader || 'Report an Issue',
          pageSubHeader:
            pageSubHeader ||
            'The submission will be shared with the team',
          artGallery: Array.isArray(artGallery) ? artGallery.map(url => ({imgPath: url})) : [],
          uiLoading: false,
          rubbishTypeID: enableAICategorization === true ? MIXED_CATEGORY_RUBBISH_TYPE_ID : '',
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ errorMsg: 'Error in retrieving the data' });
      });
  };

  componentDidMount = () => {
    const { store } = this.props;
    const authToken = store.get('user').accessToken;
    if (this.props.qrCodeSlug) {
      this.setCode(this.props.qrCodeSlug, authToken, true);
    } else {
      this.setCode(this.props.qrCodeID, authToken);
    }
  };

  onUpdateAddress = (address, gps) => {
    let newStates = { gpsCoordinateString: address };
    if (gps) {
      newStates['gpsCoordinates'] = gps;
    }
    document
      .getElementById('input-addres-type-ahead')
      .classList.remove('is-invalid');
    this.setState(newStates);
  };

  triggerPhoto = (e) => {
    e.preventDefault();
    document.getElementById('rubbishPhoto').click();
  };

  triggerPhotoAfter = (e) => {
    e.preventDefault();
    document.getElementById('rubbishAfterPhoto').click();
  };

  scrollToBottom = (e) => {
    smoothscroll.polyfill();
    // console.log(document.getElementById("scroll-to-view"));
    window.document
      .getElementById('scroll-to-view')
      .scrollIntoView({ behavior: 'smooth' });
  };

  showPhotoPreview = (e) => {
    const { photoPreview } = this.state;
    const file = document.querySelector('#rubbishPhoto').files[0];
    window.setState = this.setState;
    if (file) {
      // console.log('resizeeee');

      try {
        Resizer.imageFileResizer(
          e.target.files[0],
          800,
          800,
          'JPEG',
          100,
          0,
          (uri) => {
            let photoPreview = <img className="photo-preview" src={uri} />;
            this.setState({ photoPreview: photoPreview });
          },
          'base64',
          200,
          200,
        );
        Resizer.imageFileResizer(
          e.target.files[0],
          800,
          800,
          'JPEG',
          100,
          0,
          (uri) => {
            var reader = new FileReader();
            // reader.setState = this.setState;
            //read exif from original file, and apply to newly resized file
            try {
              reader.readAsDataURL(e.target.files[0]);
              reader.onload = () => {
                var reader2 = new FileReader();
                reader2.readAsDataURL(uri);
                reader2.onload = () => {
                  try {
                    var exifObj = piexif.load(reader.result);
                    if (exifObj['0th'][274] === 6) {
                      //idk but this fixes the wierd rotations, 6 tells the computer to rotate it, 0 says dont
                      exifObj['0th'][274] = 0;
                    }
                    var exifStr = piexif.dump(exifObj);

                    let newFile = piexif.insert(exifStr, reader2.result);
                    var exifObj2 = piexif.load(newFile);
                    this.setState({
                      photoString: dataURLtoFile(newFile, 'image-upload'),
                    });
                  } catch (err) {
                    console.log(err);
                    this.setState({
                      photoString: uri,
                    });
                  }
                };
                reader2.onerror = function (error) {
                  console.log('Error: ', error);
                };
              };
              reader.onerror = function (error) {
                console.log('Error: ', error);
              };
            } catch (err) {
              console.log(err);
              this.setState({
                photoString: uri,
              });
            }
          },
          'file',
          200,
          200,
        );
      } catch (err) {
        console.log(err);
        // this.setState({ photoPreviewAfter: "Error Attaching Photo", photoString: null });
      }
    }
  };

  showPhotoPreviewAfter = (e) => {
    const { photoPreviewAfter } = this.state;
    const file = document.querySelector('#rubbishAfterPhoto').files[0];
    if (file) {
      try {
        Resizer.imageFileResizer(
          e.target.files[0],
          500,
          500,
          'JPEG',
          100,
          0,
          (uri) => {
            let photoPreviewAfter = <img className="photo-preview" src={uri} />;
            this.setState({ photoPreviewAfter: photoPreviewAfter });
          },
          'base64',
          200,
          200,
        );
        Resizer.imageFileResizer(
          e.target.files[0],
          500,
          500,
          'JPEG',
          100,
          0,
          (uri) => {
            var reader = new FileReader();
            // reader.setState = this.setState;
            //read exif from original file, and apply to newly resized file
            try {
              reader.readAsDataURL(e.target.files[0]);
              reader.onload = () => {
                var reader2 = new FileReader();
                reader2.readAsDataURL(uri);
                reader2.onload = () => {
                  try {
                    var exifObj = piexif.load(reader.result);
                    if (exifObj['0th'][274] === 6) {
                      //idk but this fixes the wierd rotations, 6 tells the computer to rotate it, 0 says dont
                      exifObj['0th'][274] = 0;
                    }
                    var exifStr = piexif.dump(exifObj);

                    let newFile = piexif.insert(exifStr, reader2.result);
                    var exifObj2 = piexif.load(newFile);
                    this.setState({
                      photoStringAfter: dataURLtoFile(newFile, 'image-upload'),
                    });
                  } catch (err) {
                    console.log(err);
                    this.setState({
                      photoStringAfter: uri,
                    });
                  }
                };
                reader2.onerror = function (error) {
                  console.log('Error: ', error);
                };
              };
              reader.onerror = function (error) {
                console.log('Error: ', error);
              };
            } catch (err) {
              console.log(err);
              this.setState({
                photoStringAfter: uri,
              });
            }
          },
          'file',
          200,
          200,
        );
      } catch (err) {
        console.log(err);
        this.setState({
          photoPreviewAfter: 'Error Attaching Photo',
          photoStringAfter: null,
        });
      }
    }
  };

  handleCheck = (event) => {
    if (event.target.checked) {
      this.setState({ [event.target.name]: true });
    } else {
      this.setState({ [event.target.name]: false });
    }
  };

  increaseQty = (e) => {
    const { qrQuantity } = this.state;
    let newQuantity = qrQuantity + 1;
    this.setState({
      qrQuantity: newQuantity,
    });
  };

  decreaseQty = (e) => {
    const { qrQuantity } = this.state;
    let newQuantity = qrQuantity - 1;
    if (newQuantity < 1) {
      newQuantity = 1;
    }
    this.setState({
      qrQuantity: newQuantity,
    });
  };

  formatToPhone = (event) => {
    // I am lazy and don't like to type things more than once
    const target = event.target;
    const input = target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    if (input.length > 6) {
      target.value = `(${zip}) ${middle} - ${last}`;
    } else if (input.length > 3) {
      target.value = `(${zip}) ${middle}`;
    } else if (input.length > 0) {
      target.value = `(${zip}`;
    }
    this.setState({ [event.target.name]: event.target.value });
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onCheck = (event) => {
    if (event.target.checked) {
      this.setState({ [event.target.name]: true });
    } else {
      this.setState({ [event.target.name]: false });
    }
  };

  onSelectType = (event) => {
    document.getElementById('rubbishTypeID').classList.remove('is-invalid');
    this.setState({ [event.target.name]: event.target.value });
  };

  onShareLocation = (e) => {
    e.preventDefault();
    const { store } = this.props;
    const { qrCodeReportId, gpsCoordinates } = this.state;
    window.this = this;
    // window.this.setState({
    //   loading: true
    // });
    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };
    function success(pos) {
      var crd = pos.coords;
      window.this.setState({
        loading: false,
        gpsCoordinates: {
          latitude: crd.latitude,
          longitude: crd.longitude,
          accuracy: crd.accuracy,
        },
        gpsCoordinateString:
          'Latitude: ' +
          crd.latitude +
          ', Longitude: ' +
          crd.longitude +
          ', Accuracy: ' +
          crd.accuracy,
      });

      Geocode.fromLatLng(crd.latitude, crd.longitude).then(
        (response) => {
          const address = response.results[0].formatted_address;
          // console.log(address);
          window.this.setState({
            loading: false,
            gpsCoordinates: {
              latitude: crd.latitude,
              longitude: crd.longitude,
              accuracy: crd.accuracy,
            },
            gpsCoordinateString: address,
          });
        },
        (error) => {
          console.error(error);
        },
      );
    }
    function error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
      window.this.setState({
        loading: false,
      });
    }
    navigator.geolocation.getCurrentPosition(success, error, options);
  };

  onDone = (e) => {
    const { store, codeID } = this.props;
    const authToken = store.get('user').accessToken;
    const currentTeam = store.get('currentTeam');
    var formData = new FormData();

    const formRequest = {
      phoneNumber: this.state.phoneNumber,
      emailAddress: this.state.emailAddress,
      recievedNotify: true,
      subscribeToNewsletter: this.state.subscribeToNewsletter,
    };
    window.this = this;
    axios
      .post(
        `/api/public/team/${this.state.team.teamID}/report/${this.state.reportID}`,
        formRequest,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        },
      )
      .then(function () {
        window.this.setState({ stage: 4 });
        // console.log('SUCCESS!!');
      })
      .catch(function () {
        console.log('FAILURE!!');
      });
  };

  onSubmit = (e) => {
    // this.setState({stage: 2});
    if (this.state.stage == 2) {
      const { store } = this.props;
      const authToken = store.get('user').accessToken;

      let allAreFilled = true;
      window.this = this;
      document
        .getElementById('submitForm')
        .querySelectorAll('input[required]')
        .forEach(function (i) {
          if (!allAreFilled) return;
          // console.log(i);
          if (!i.value) {
            allAreFilled = false;
            i.classList.add('is-invalid');
          }
          if (i.value == '0') {
            allAreFilled = false;
            i.classList.add('is-invalid');
          }
        });
      if (!allAreFilled) {
        alert('Please include required details');
      } else {
        this.setState({ enableSubmit: false });
        var formData = new FormData();
        let userAgent = navigator.userAgent ? navigator.userAgent : '';

        let typeIndex = this.state.code.rubbishTypes.findIndex(
          (obj) => obj.rubbishTypeID == this.state.rubbishTypeID,
        );
        let rubbishTypeString = this.state.code.rubbishTypes[typeIndex];

        formData.append('rubbishTypeID', this.state.rubbishTypeID);
        formData.append(
          'rubbishTypeString', this.state.rubbishTypeID === MIXED_CATEGORY_RUBBISH_TYPE_ID 
            ? MIXED_CATEGORY_RUBBISH_TYPE_NAME 
            : rubbishTypeString['title']
        );
        
        formData.append('logReport', this.state.logReport);
        const ts = new Date().getTime() / 1000;
        formData.append('localTimeStamp', ts);
        formData.append('qrCodeID', this.state.code.qrCodeID);
        formData.append(
          'gpsLat',
          this.state.gpsCoordinates['latitude'] || this.state.code.lat || '',
        );
        formData.append(
          'gpsLong',
          this.state.gpsCoordinates['longitude'] || this.state.code.long || '',
        );
        formData.append('gpsCoordinateString', this.state.gpsCoordinateString);
        formData.append('comments', this.state.comments);
        formData.append('qrQuantity', this.state.qrQuantity);
        formData.append('userAgent', userAgent);
        if (this.state.photoString) {
          formData.append('photo', this.state.photoString);
        }
        if (this.state.photoStringAfter) {
          formData.append('rubbishAfterPhoto', this.state.photoStringAfter);
        }

        axios
          .post(`/api/public/team/${this.state.team.teamID}/report`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${authToken}`,
            },
          })
          .then(function (response) {
            window.this.setState({
              enableSubmit: true,
              stage: 3,
              reportID: response.data.reportID,
            });
            // console.log(response.data);
          });
      }
    } else {
      this.setState({ stage: 2 });
    }
  };

  handleNext = () => {
    this.setState({ activeStep: this.state.activeStep + 1 });
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  handleStepChange = (step) => {
    this.setState({ activeStep: step });
  };

  submitAgain = () => {
    location.reload();
  };

  render() {
    const { classes } = this.props;
    const { uiLoading, activeStep, code, team } = this.state;

    const { enableAICategorization } = (team ?? {});
    const { rubbishTypes } = (code ?? {});

    let issueTypeMenuItems = [];

    if (enableAICategorization === true) {
      issueTypeMenuItems.push(
        <MenuItem key={MIXED_CATEGORY_RUBBISH_TYPE_ID} value={MIXED_CATEGORY_RUBBISH_TYPE_ID}>
          {MIXED_CATEGORY_RUBBISH_TYPE_LABEL}
        </MenuItem>
      );
    }

    Object.keys(rubbishTypes ?? {}).forEach((key) => {
      issueTypeMenuItems.push(
        <MenuItem
          key={key}
          value={
            rubbishTypes[key].rubbishTypeID
          }
        >
          {rubbishTypes[key].title}
        </MenuItem>
      );
    });

    let content;

    if (this.state.uiLoading === true) {
      content = (
        <div className={classes.root}>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            {this.state.uiLoading && (
              <CircularProgress size={150} className={classes.uiProgess} />
            )}
          </main>
        </div>
      );
    } else {
      let primaryImage =
        this.state.code && this.state.code.artImageURL ? (
          <img className="bin-art rounded" src={this.state.code.artImageURL} />
        ) : (
          ''
        );

      if (this.state.stage == 4) {
        content = (
          <>
            <p className="text-center">
              <img className="success-check" src="/images/corgi.png" />
            </p>
            <h4 className="text-center">
              {this.state.team.finalTitle || 'Issue Reported Successfully'}
            </h4>
            <h5 className="text-center text-secondary">
              {ReactHtmlParser(this.state.team.finalSubtitle) ||
                "Great! We'll send you a message when the issue has been resolved."}
            </h5>

            <p className="text-center report-another-issue">
              <a href="#" onClick={this.submitAgain}>
                {this.state.team.reportAnotherOverride &&
                this.state.team.reportAnotherOverride.length > 0
                  ? this.state.team.reportAnotherOverride
                  : this.state.reportText + ' another issue'}
              </a>
            </p>
          </>
        );
      } else if (this.state.stage == 3) {
        if (this.props.logIssue) {
          content = (
            <div id="submitForm" className={classes.root}>
              <Grid>
                <Grid item sm={12}>
                  <p className="text-center">
                    <img className="success-check" src="/images/corgi.png" />
                  </p>
                </Grid>
                <Grid item sm={12}>
                  <h4 className="text-center">Issue Logged Successfully</h4>
                </Grid>
                <Grid item sm={12}>
                  <h5 className="text-center text-secondary">
                    Thank you for your submission.
                  </h5>
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Button
                      onClick={this.submitAgain}
                      className={classes.button}
                      variant="contained"
                      size="large"
                      color="primary"
                      id="reportRubbish"
                    >
                      {this.state.team.reportAnotherOverride &&
                      this.state.team.reportAnotherOverride.length > 0
                        ? this.state.team.reportAnotherOverride
                        : this.state.reportText + ' another issue'}
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
              <AppBar position="fixed" className={classes.appBar}>
                <div className="fixed-bottom footer">
                  <Divider />
                  <div className="row">
                    <div className="footer-img col-6 text-right">
                      <a href="https://www.rubbish.love">
                        <img src="https://qr.rubbish.love/images/powered_by_rubbish.png" />
                      </a>
                    </div>
                    <div className="footer-img col-6">
                      <a
                        target="_blank"
                        href={this.state.team.footerPhotoLink || ''}
                      >
                        <img src={this.state.team.footerPhotoURL || ''} />
                      </a>
                    </div>
                  </div>
                </div>
              </AppBar>
            </div>
          );
        } else {
          let newsletterText = 'Sign up to our newsletter!';
          if (
            this.state.team.newsletterText &&
            this.state.team.newsletterText.length > 0
          ) {
            newsletterText = this.state.team.newsletterText;
          }
          if (this.state.team.skipNotificationRequest) {
            content = (
              <>
                <p className="text-center">
                  <img className="success-check" src="/images/corgi.png" />
                </p>
                <h4 className="text-center">
                  {this.state.team.successTitle ||
                    'Issue Reported Successfully'}
                </h4>
                <h5 className="text-center text-secondary"></h5>

                <p className="text-center report-another-issue">
                  <a href="#" onClick={this.submitAgain}>
                    {this.state.team.reportAnotherOverride &&
                    this.state.team.reportAnotherOverride.length > 0
                      ? this.state.team.reportAnotherOverride
                      : this.state.reportText + ' another issue'}
                  </a>
                </p>
              </>
            );
          } else {
            content = (
              <div id="submitForm" className={classes.root}>
                <Grid>
                  <Grid item sm={12}>
                    <p className="text-center">
                      <img className="success-check" src="/images/corgi.png" />
                    </p>
                  </Grid>
                  <Grid item sm={12}>
                    <h4 className="text-center">
                      {this.state.team.successTitle ||
                        'Issue Reported Successfully'}
                    </h4>
                  </Grid>
                  <Grid item sm={12}>
                    <h5 className="text-center text-secondary">
                      {ReactHtmlParser(this.state.team.successSubtitle) ||
                        'Thank you for your submission. Leave your info below to get a message when the issue is resolved.'}
                    </h5>
                  </Grid>
                  <Grid item sm={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <TextField
                        id="outlined-basic"
                        name="phoneNumber"
                        maxLength="16"
                        onChange={this.formatToPhone}
                        type="tel"
                        label="Phone Number"
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <TextField
                        id="outlined-basic"
                        name="emailAddress"
                        onChange={this.onChange}
                        type="email"
                        label="Email Address"
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>

                  <Grid item sm={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.subscribeToNewsletter || false}
                            name="subscribeToNewsletter"
                            onChange={this.handleCheck}
                            color="primary"
                          />
                        }
                        label={newsletterText}
                        labelPlacement="end"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <Button
                        onClick={this.onDone}
                        className={classes.button}
                        variant="contained"
                        size="large"
                        color="primary"
                        id="reportRubbish"
                      >
                        <div className="hide-thanks">Done</div>
                      </Button>
                    </FormControl>
                    <p className="text-center report-another-issue">
                      <a href="#" onClick={this.submitAgain}>
                        {this.state.team.reportAnotherOverride &&
                        this.state.team.reportAnotherOverride.length > 0
                          ? this.state.team.reportAnotherOverride
                          : this.state.reportText + ' another issue'}
                      </a>
                    </p>
                  </Grid>
                </Grid>
                <AppBar position="fixed" className={classes.appBar}>
                  <div className="fixed-bottom footer">
                    <Divider />
                    <div className="row">
                      <div className="footer-img col-6 text-right">
                        <a href="https://www.rubbish.love">
                          <img src="https://qr.rubbish.love/images/powered_by_rubbish.png" />
                        </a>
                      </div>
                      <div className="footer-img col-6">
                        <a
                          target="_blank"
                          href={this.state.team.footerPhotoLink || ''}
                        >
                          <img src={this.state.team.footerPhotoURL || ''} />
                        </a>
                      </div>
                    </div>
                  </div>
                </AppBar>
              </div>
            );
          }
        }
      } else if (this.state.stage == 2) {
        content = (
          <div id="submitForm" className={classes.root}>
            <Grid
              spacing={1}
              justifyContent="center"
              alignItems="center"
              container
            >
              <Grid item md={12} xs={12}>
                <h4 className="text-center mt-5">
                  {this.props.logIssue
                    ? this.state.reportText + ' an Issue'
                    : this.state.pageHeader}
                </h4>
                <h5 className="text-center text-secondary">
                  {ReactHtmlParser(this.state.pageSubHeader)}
                </h5>
              </Grid>
              <Grid item md={12} xs={12}>
                <Divider className="art-divider" />
                <p className={classes.alignCenter + ' bin-identifier'}>
                  {this.state.code.identifier} at {this.state.code.location}
                </p>
              </Grid>

              <Grid item md={12} xs={12}>
                <FormControl
                  className={classes.selectControl}
                >
                  <InputLabel
                    className={classes.selectLabel}
                    id="rubbishTypeIDLabel"
                  >
                    {this.state.team.selectIssueOverride &&
                    this.state.team.selectIssueOverride.length > 0
                      ? this.state.team.selectIssueOverride
                      : 'Select issue type...'}
                  </InputLabel>
                  <Select
                    required
                    fullWidth
                    value={this.state.rubbishTypeID || ''}
                    name="rubbishTypeID"
                    label="Select issue Type..."
                    id="rubbishTypeID"
                    onChange={this.onSelectType}>
                    <MenuItem key={0} value="">
                      {this.state.team.selectIssueOverride &&
                      this.state.team.selectIssueOverride.length > 0
                        ? this.state.team.selectIssueOverride
                        : 'Select issue type...'}
                    </MenuItem>
                    {issueTypeMenuItems}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={7} xs={6}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Button
                    className={classes.button}
                    variant="outlined"
                    size="large"
                    endIcon={<CameraAltIcon />}
                    onClick={this.triggerPhoto}
                  >
                    {this.state.photoPreview}
                  </Button>
                  <input
                    onChange={this.showPhotoPreview}
                    id="rubbishPhoto"
                    name="rubbishPhoto"
                    type="file"
                    accept="image/*"
                  />
                </FormControl>
              </Grid>
              <Grid item md={5} xs={6}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <ButtonGroup
                    fullWidth
                    size="large"
                    color="primary"
                    aria-label="outlined primary button group"
                  >
                    <Button className={classes.button}>
                      <span className="qty-indicator">Qty</span>{' '}
                      <span className="qty-count">{this.state.qrQuantity}</span>
                    </Button>
                    <Button
                      className={classes.button}
                      onClick={this.decreaseQty}
                    >
                      <RemoveIcon />
                    </Button>
                    <Button
                      className={classes.button}
                      onClick={this.increaseQty}
                    >
                      <AddIcon />
                    </Button>
                  </ButtonGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    id="outlined-basic"
                    name="comments"
                    onChange={this.onChange}
                    multiline
                    label="Leave a comment on the issue..."
                    minRows={3}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>

              {this.props.logIssue ? (
                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.logReport || false}
                          name="logReport"
                          onChange={this.handleCheck}
                          color="primary"
                        />
                      }
                      label={
                        <>
                          <strong>I already fixed it!</strong> (Marks issue as
                          resolved)
                        </>
                      }
                      labelPlacement="end"
                    />
                  </FormControl>

                  {this.state.logReport ? (
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <Button
                        className={classes.button}
                        variant="outlined"
                        size="large"
                        endIcon={<CameraAltIcon />}
                        onClick={this.triggerPhotoAfter}
                      >
                        {this.state.photoPreviewAfter}
                      </Button>
                      <input
                        onChange={this.showPhotoPreviewAfter}
                        id="rubbishAfterPhoto"
                        name="rubbishAfterPhoto"
                        type="file"
                        accept="image/*"
                      />
                    </FormControl>
                  ) : (
                    ''
                  )}
                </Grid>
              ) : (
                ''
              )}

              {this.state.code.enableLocationPicker ? (
                <>
                  <Grid item md={12} xs={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <LocationSearchInput
                        address={this.state.gpsCoordinateString}
                        handleAddressChange={this.onUpdateAddress}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <Button
                        className={classes.button}
                        variant="outlined"
                        onClick={this.onShareLocation}
                        size="large"
                        startIcon={<MyLocationIcon />}
                      >
                        {' '}
                        Share Current Location
                      </Button>
                    </FormControl>
                  </Grid>
                </>
              ) : (
                ''
              )}

              <Grid item xs={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Button
                    className={classes.button}
                    variant="contained"
                    size="large"
                    onClick={this.onSubmit}
                    color="primary"
                    disabled={!this.state.enableSubmit}
                  >
                    {this.state.team.submitButtonOverride || 'Submit'}
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
            <AppBar position="fixed" className={classes.appBar}>
              <div className="fixed-bottom footer">
                <Divider />
                <div className="row">
                  <div className="footer-img col-6 text-right">
                    <a href="https://www.rubbish.love">
                      <img src="https://qr.rubbish.love/images/powered_by_rubbish.png" />
                    </a>
                  </div>
                  <div className="footer-img col-6">
                    <a href={this.state.team.footerPhotoLink || ''}>
                      <img src={this.state.team.footerPhotoURL || ''} />
                    </a>
                  </div>
                </div>
              </div>
            </AppBar>
          </div>
        );
      } else {
        let qrCodeArt, footer;

        const maxSteps = this.state.artGallery.length;

        let qrcode = this.state.code;
        if (
          this.state.code.artImageURL !== undefined &&
          this.state.code.artImageURL.length > 0
        ) {
          var artistCaptionPreface = qrcode.artistName
            ? qrcode.artistCaptionPreface
              ? qrcode.artistCaptionPreface
              : 'Artwork By'
            : '';
          var artistActionText = qrcode.artistActionText
            ? qrcode.artistActionText
            : 'View full artwork and more about the artist';

          var scrollToLink = '';
          if (
            qrcode.disableMoreArt === undefined ||
            qrcode.disableMoreArt === false
          ) {
            scrollToLink = (
              <a
                href="#"
                onClick={() =>
                  document
                    .getElementById('scroll-to-view')
                    .scrollIntoView({ behavior: 'smooth' })
                }
              >
                {artistActionText}
              </a>
            );
          }

          qrCodeArt = (
            <div className="text-center pb-10">
              <div className="art-box text-center">
                <p>
                  {qrcode.identifier} at {qrcode.location}
                </p>

                <img className="bin-art rounded" src={qrcode.artImageURL} />
                <br />
                <p>
                  {artistCaptionPreface} <strong>{qrcode.artistName}</strong>
                  {artistCaptionPreface.length > 0 ||
                  (qrcode.artistName && qrcode.artistName.length > 0) ? (
                    <br />
                  ) : (
                    ''
                  )}
                  {scrollToLink}
                </p>
              </div>
            </div>
          );

          let displayGallery = '';
          if (this.state.artGallery.length > 0) {
            displayGallery = (
              <div className="art-gallery-slides">
                <AutoPlaySwipeableViews
                  axis={'x'}
                  interval={5000}
                  index={activeStep}
                  className={classes.alignCenter}
                  onChangeIndex={this.handleStepChange}
                  animateHeight={false}
                  enableMouseEvents
                >
                  {this.state.artGallery.map((step, index) => (
                    <div key={index}>
                      {Math.abs(activeStep - index) <= 2 ? (
                        <img
                          className={classes.img + ' rounded'}
                          src={step.imgPath}
                        />
                      ) : null}
                    </div>
                  ))}
                </AutoPlaySwipeableViews>
                <MobileStepper
                  steps={maxSteps}
                  position="static"
                  variant="text"
                  activeStep={activeStep}
                  nextButton={
                    <Button
                      size="small"
                      onClick={this.handleNext}
                      disabled={activeStep === maxSteps - 1}
                    >
                      Next
                      <KeyboardArrowRight />
                    </Button>
                  }
                  backButton={
                    <Button
                      size="small"
                      onClick={this.handleBack}
                      disabled={activeStep === 0}
                    >
                      <KeyboardArrowLeft />
                      Back
                    </Button>
                  }
                />
              </div>
            );
          }

          if (
            qrcode.disableMoreArt === undefined ||
            qrcode.disableMoreArt === false
          ) {
            var artistLinkText = qrcode.artistLinkText
              ? qrcode.artistLinkText
              : 'Visit ' + qrcode.artistName + ' at ' + qrcode.artistLink;
            footer = (
              <>
                <div
                  className={classes.alignCenter + ' artist-info'}
                  id="scroll-to-view"
                  ref={this.artEnd}
                >
                  <a id="about-artist"></a>
                  <h4>{qrcode.artTitle}</h4>

                  <p className="text-left">
                    {ReactHtmlParser(qrcode.artDescription)}
                  </p>

                  {displayGallery}
                  {qrcode.artistLink && qrcode.artistLink.length > 0 ? (
                    <p className="text-center">
                      <a
                        href={
                          qrcode.artistLink.includes('http')
                            ? qrcode.artistLink
                            : 'https://' + qrcode.artistLink
                        }
                      >
                        {artistLinkText}
                      </a>
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </>
            );
          }
        } else {
          qrCodeArt = (
            <>
              <img
                src={qrcode.imageURL}
                className="trash-icon"
                id="trash-icon"
              />
            </>
          );
          footer = <></>;
        }

        content = (
          <div id="submitForm" className={classes.root}>
            <Grid
              justifyContent="center"
              spacing={3}
              alignItems="center"
              container
            >
              <Grid item md={12} xs={12}>
                <h4 className="text-center mt-5">
                  {this.props.logIssue
                    ? this.state.reportText + ' an Issue'
                    : this.state.pageHeader}
                </h4>
                <h5 className="text-center text-secondary">
                  {ReactHtmlParser(this.state.pageSubHeader)}
                </h5>
              </Grid>
              <Grid item md={12} xs={12}>
                {qrCodeArt}
              </Grid>
              <Grid item md={12} xs={12}>
                <Button
                  fullWidth
                  className={classes.button}
                  variant="contained"
                  size="large"
                  onClick={this.onSubmit}
                  color="primary"
                >

                {this.state.team.reportFirstOverride &&
                this.state.team.reportFirstOverride.length > 0
                  ? this.state.team.reportFirstOverride
                  : this.state.reportText + ' an issue'}
                </Button>
              </Grid>
              <Grid item md={12} xs={12}>
                {footer}
              </Grid>
            </Grid>
            <AppBar position="fixed" className={classes.appBar}>
              <div className="fixed-bottom footer">
                <Divider />
                <div className="row">
                  <div className="footer-img col-6 text-right">
                    <a href="https://www.rubbish.love">
                      <img src="https://qr.rubbish.love/images/powered_by_rubbish.png" />
                    </a>
                  </div>
                  <div className="footer-img col-6">
                    {this.state.team.footerPhotoURL &&
                      this.state.team.footerPhotoURL.length > 0 && (
                        <a href={this.state.team.footerPhotoLink || ''}>
                          <img src={this.state.team.footerPhotoURL || ''} />
                        </a>
                      )}
                  </div>
                </div>
              </div>
            </AppBar>
          </div>
        );
      }
    }

    return (
      <>
        <img src="/images/newcloud.png" className="background-clouds" />
        <Container maxWidth="sm" className="reporter-view">
          {content}
        </Container>
      </>
    );
  }
}

export default Store.withStore(withStyles(styles)(QrCode));