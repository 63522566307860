import React, { Component } from 'react';
import {
  Button,
  Grid,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import Store from '../../services/Store';
import axios from '../../helpers/axios';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ListIcon from '@mui/icons-material/List';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';
import { withStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';

const styles = (theme) => ({
  buttonProperty: {
    position: 'absolute',
    top: '50%',
  },
  uiProgess: {
    position: 'fixed',
    zIndex: '1000',
    height: '31px',
    width: '31px',
    left: '50%',
    top: '35%',
  },
  progess: {
    position: 'absolute',
  },
  uploadButton: {
    marginLeft: '8px',
    margin: theme.spacing(1),
  },
  customError: {
    color: 'red',
    fontSize: '0.8rem',
    marginTop: 10,
  },
  saveRight: {
    marginLeft: 'auto',
    marginTop: '10px',
    float: 'right',
  },
  gridContainer: {
    flexGrow: 1,
  },
  paper: {
    position: 'absolute',
    width: '60%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
});

class ManageInvites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      inviteList: [],
    };
  }

  handleOpen = () => {
    const { store } = this.props;
    const authToken = store.get('user').accessToken;
    const currentTeam = store.get('currentTeam');

    axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
    axios
      .get(`/api/team/${currentTeam}/invites`)
      .then((response) => {
        this.setState({
          inviteList: response.data,
          modalOpen: true,
          uiLoading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ errorMsg: 'Error in retrieving the data' });
      });
  };
  updateFormValues = () => {
    const { store } = this.props;
    const { newAccount } = this.state;
    const authToken = store.get('user').accessToken;
    const team = store.get('currentTeam');
    axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };

    axios
      .post(`/api/team/${team}/users/${this.props.userID}`, formRequest)
      .then((teamResponse) => {
        this.props.setToast('Member Updated');
      })
      .catch((error) => {
        // if (error..status === 403) {
        //   this.props.history.push('/login');
        // }
        console.log(error);
        this.setState({
          buttonLoading: false,
        });
      });
  };
  removeInvite = (inviteID, email) => {
    if (
      window.confirm(
        "Are you sure you'd like to remove the invite to " + email + '?',
      )
    ) {
      const { store } = this.props;
      const { inviteList } = this.state;
      const team = store.get('currentTeam');
      const authToken = store.get('user').accessToken;
      axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
      axios
        .delete(`/api/team/${team.teamID}/remove_invite/${inviteID}`)
        .then((teamResponse) => {
          let newInviteList = inviteList.filter(
            (invite) => invite.teamInviteID !== inviteID,
          );
          this.setState({
            inviteList: newInviteList,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            buttonLoading: false,
          });
        });
    }
  };
  handleClose = () => {
    this.setState({ modalOpen: false });
  };
  handleToastClose = () => {
    this.setState({ toastOpen: false });
  };
  handleChange = (event) => {
    // console.log(event.target.name);
    // console.log(event.target.value);
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    const { userID, classes } = this.props;
    const { modalOpen } = this.state;

    let modalStyle = {
      top: `45%`,
      left: `50%`,
      transform: `translate(-45%, -50%)`,
    };

    return <>
      <Tooltip title="Show All Invites">
        <IconButton
          onClick={() => this.handleOpen(userID)}
          variant="outline"
          color="primary"
          size="large">
          <ListIcon fontSize="large" />
        </IconButton>
      </Tooltip>
      <Dialog
        maxWidth="md"
        fullWidth
        open={modalOpen}
        onClose={this.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DialogContent>
          <h2 id="simple-modal-title">Team Invites</h2>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <List>
                {this.state.inviteList.map((invite) => {
                  return (
                    <ListItem
                      key={invite.teamInviteID}
                      alignItems="flex-start"
                    >
                      <ListItemText
                        primary={invite.emailAddress}
                        secondary={
                          invite.inviteStatus +
                          ' :: ' +
                          (invite.inviteStatus === 'PENDING'
                            ? 'Sent At ' + invite.invitedAt
                            : 'Accepted at ' + invite.statusUpdatedAt)
                        }
                      />

                      {invite.inviteStatus === 'PENDING' ? (
                        <ListItemSecondaryAction>
                          <Tooltip title="Cancel Invite">
                            <IconButton
                              onClick={() =>
                                this.removeInvite(
                                  invite.teamInviteID,
                                  invite.emailAddress,
                                )
                              }
                              edge="end"
                              aria-label="delete"
                              size="large">
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      ) : (
                        ''
                      )}
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" type="submit" onClick={this.handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>;
  }
}

export default Store.withStore(withStyles(styles)(ManageInvites));
