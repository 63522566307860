import React, { Component } from 'react';
import {
  Button,
  Grid,
  TextField,
  IconButton,
  Divider,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import Store from '../../services/Store';
import axios from '../../helpers/axios';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import EditIcon from '@mui/icons-material/Edit';
import Modal from '@mui/material/Modal';
import { withStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';

const inputStyle = {
  p: 1,
}

const styles = (theme) => ({
  buttonProperty: {
    position: 'absolute',
    top: '50%',
  },
  uiProgess: {
    position: 'fixed',
    zIndex: '1000',
    height: '31px',
    width: '31px',
    left: '50%',
    top: '35%',
  },
  progess: {
    position: 'absolute',
  },
  uploadButton: {
    marginLeft: '8px',
    margin: theme.spacing(1),
  },
  customError: {
    color: 'red',
    fontSize: '0.8rem',
    marginTop: 10,
  },
  saveRight: {
    marginLeft: 'auto',
    marginTop: '10px',
    float: 'right',
  },
  gridContainer: {
    flexGrow: 1,
  },
  paper: {
    position: 'absolute',
    width: '60%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
});

class EditTeamMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    };
  }

  handleOpen = () => {
    const { store } = this.props;
    const authToken = store.get('user').accessToken;
    const currentTeam = store.get('currentTeam');

    axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
    axios
      .get(`/api/team/${currentTeam}/users/${this.props.userID}`)
      .then((response) => {
        let notifications = {};
        if (response.data.notificationTimes) {
          if (response.data.notificationTimes.monday) {
            // console.log('set monday');
            notifications['mondayActive'] =
              response.data.notificationTimes.monday.active || false;
            notifications['mondayStart'] =
              response.data.notificationTimes.monday.startTime || '';
            notifications['mondayEnd'] =
              response.data.notificationTimes.monday.endTime || '';
          }
          if (response.data.notificationTimes.tuesday) {
            notifications['tuesdayActive'] =
              response.data.notificationTimes.tuesday.active || false;
            notifications['tuesdayStart'] =
              response.data.notificationTimes.tuesday.startTime || '';
            notifications['tuesdayEnd'] =
              response.data.notificationTimes.tuesday.endTime || '';
          }
          if (response.data.notificationTimes.wednesday) {
            notifications['wednesdayActive'] =
              response.data.notificationTimes.wednesday.active || false;
            notifications['wednesdayStart'] =
              response.data.notificationTimes.wednesday.startTime || '';
            notifications['wednesdayEnd'] =
              response.data.notificationTimes.wednesday.endTime || '';
          }
          if (response.data.notificationTimes.thursday) {
            notifications['thursdayActive'] =
              response.data.notificationTimes.thursday.active || false;
            notifications['thursdayStart'] =
              response.data.notificationTimes.thursday.startTime || '';
            notifications['thursdayEnd'] =
              response.data.notificationTimes.thursday.endTime || '';
          }
          if (response.data.notificationTimes.friday) {
            notifications['fridayActive'] =
              response.data.notificationTimes.friday.active || false;
            notifications['fridayStart'] =
              response.data.notificationTimes.friday.startTime || '';
            notifications['fridayEnd'] =
              response.data.notificationTimes.friday.endTime || '';
          }
          if (response.data.notificationTimes.saturday) {
            notifications['saturdayActive'] =
              response.data.notificationTimes.saturday.active || false;
            notifications['saturdayStart'] =
              response.data.notificationTimes.saturday.startTime || '';
            notifications['saturdayEnd'] =
              response.data.notificationTimes.saturday.endTime || '';
          }
          if (response.data.notificationTimes.sunday) {
            notifications['sundayActive'] =
              response.data.notificationTimes.sunday.active || false;
            notifications['sundayStart'] =
              response.data.notificationTimes.sunday.startTime || '';
            notifications['sundayEnd'] =
              response.data.notificationTimes.sunday.endTime || '';
          }
        }
        this.setState(notifications);
        this.setState({
          user: response.data,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          email: response.data.email,
          phoneNumber: response.data.phoneNumber,
          emailNotificationsActive: response.data.emailNotificationsActive,
          textNotificationsActive: response.data.textNotificationsActive,
          modalOpen: true,
          uiLoading: false,
        });
        // if (response.data.footerPhotoURL) {
        //   this.ssetState({
        //     photoPreview: (<img src={response.data.footerPhotoURL})
        //   });
        // }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ errorMsg: 'Error in retrieving the data' });
      });
  };
  updateFormValues = () => {
    //save details
    // console.log('wtf');
    const { store } = this.props;
    const { newAccount } = this.state;
    const authToken = store.get('user').accessToken;
    const team = store.get('currentTeam');
    axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };

    const formRequest = {
      user: {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
      },
      privateData: {
        textNotificationsActive: this.state.textNotificationsActive,
        emailNotificationsActive: this.state.emailNotificationsActive,
        phoneNumber: this.state.phoneNumber,
        notificationTimes: {
          monday: {
            active: this.state.mondayActive,
            startTime: this.state.mondayStart,
            endTime: this.state.mondayEnd,
          },
          tuesday: {
            active: this.state.tuesdayActive,
            startTime: this.state.tuesdayStart,
            endTime: this.state.tuesdayEnd,
          },
          wednesday: {
            active: this.state.wednesdayActive,
            startTime: this.state.wednesdayStart,
            endTime: this.state.wednesdayEnd,
          },
          thursday: {
            active: this.state.thursdayActive,
            startTime: this.state.thursdayStart,
            endTime: this.state.thursdayEnd,
          },
          friday: {
            active: this.state.fridayActive,
            startTime: this.state.fridayStart,
            endTime: this.state.fridayEnd,
          },
          saturday: {
            active: this.state.saturdayActive,
            startTime: this.state.saturdayStart,
            endTime: this.state.saturdayEnd,
          },
          sunday: {
            active: this.state.sundayActive,
            startTime: this.state.sundayStart,
            endTime: this.state.sundayEnd,
          },
        },
      },
    };

    axios
      .post(`/api/team/${team}/users/${this.props.userID}`, formRequest)
      .then((teamResponse) => {
        this.props.setToast('Member Updated');
      })
      .catch((error) => {
        // if (error..status === 403) {
        //   this.props.history.push('/login');
        // }
        console.log(error);
        this.setState({
          buttonLoading: false,
        });
      });
  };
  handleClose = () => {
    this.setState({ modalOpen: false });
  };
  handleToastClose = () => {
    this.setState({ toastOpen: false });
  };
  handleChange = (event) => {
    // console.log(event.target.name);
    // console.log(event.target.value);
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleCheck = (event) => {
    if (event.target.checked) {
      this.setState({ [event.target.name]: true });
    } else {
      this.setState({ [event.target.name]: false });
    }
  };

  formatToPhone = (event) => {
    // I am lazy and don't like to type things more than once
    const target = event.target;
    const input = target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    if (input.length > 6) {
      target.value = `(${zip}) ${middle} - ${last}`;
    } else if (input.length > 3) {
      target.value = `(${zip}) ${middle}`;
    } else if (input.length > 0) {
      target.value = `(${zip}`;
    }
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { userID, classes } = this.props;
    const { modalOpen } = this.state;

    let modalStyle = {
      top: `45%`,
      left: `50%`,
      transform: `translate(-45%, -50%)`,
    };

    return <>
      <Tooltip title="Edit Notification Settings">
        <IconButton
          onClick={() => this.handleOpen(userID)}
          edge="end"
          aria-label="delete"
          size="large">
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        maxWidth="md"
        fullWidth
        open={modalOpen}
        onClose={this.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DialogContent>
          <h2 id="simple-modal-title">Edit Member</h2>
          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="First Name"
                margin="dense"
                name="firstName"
                variant="outlined"
                value={this.state.firstName || ''}
                onChange={this.handleChange}
                inputProps={{
                  sx: inputStyle
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Last Name"
                margin="dense"
                name="lastName"
                variant="outlined"
                value={this.state.lastName || ''}
                onChange={this.handleChange}
                inputProps={{
                  sx: inputStyle
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Email Address"
                margin="dense"
                variant="outlined"
                value={this.state.email || ''}
                disabled={true}
                inputProps={{
                  sx: inputStyle
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Phone Number"
                margin="dense"
                name="phoneNumber"
                variant="outlined"
                value={this.state.phoneNumber || ''}
                onChange={this.handleChange}
                inputProps={{
                  sx: inputStyle
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.emailNotificationsActive || false}
                    name="emailNotificationsActive"
                    onChange={this.handleCheck}
                    color="primary"
                  />
                }
                label="Enable Email Notifications"
                labelPlacement="end"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.textNotificationsActive || false}
                    name="textNotificationsActive"
                    onChange={this.handleCheck}
                    color="primary"
                  />
                }
                label="Enable Text Message Notifications"
                labelPlacement="end"
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <Divider />
              <br />
              <h4>Notification Active Hours</h4>
            </Grid>

            <Grid item md={2} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.sundayActive || false}
                    name="sundayActive"
                    onChange={this.handleCheck}
                    color="primary"
                  />
                }
                label="Sunday"
                labelPlacement="end"
              />
            </Grid>

            <Grid item md={5} xs={12}>
              <TextField
                variant="standard"
                id="sundayStart"
                fullWidth
                label="Start Time"
                type="time"
                name="sundayStart"
                value={this.state.sundayStart || ''}
                className={classes.textField}
                onChange={this.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }} />
            </Grid>
            <Grid item md={5} xs={12}>
              <TextField
                variant="standard"
                id="sundayEnd"
                fullWidth
                label="End Time"
                type="time"
                name="sundayEnd"
                value={this.state.sundayEnd || ''}
                className={classes.textField}
                onChange={this.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }} />
            </Grid>

            <Grid item md={2} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.mondayActive || false}
                    name="mondayActive"
                    onChange={this.handleCheck}
                    color="primary"
                  />
                }
                label="Monday"
                labelPlacement="end"
              />
            </Grid>

            <Grid item md={5} xs={12}>
              <TextField
                variant="standard"
                id="mondayStart"
                fullWidth
                label="Start Time"
                type="time"
                name="mondayStart"
                value={this.state.mondayStart || ''}
                className={classes.textField}
                onChange={this.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }} />
            </Grid>
            <Grid item md={5} xs={12}>
              <TextField
                variant="standard"
                id="mondayEnd"
                fullWidth
                label="End Time"
                type="time"
                name="mondayEnd"
                value={this.state.mondayEnd || ''}
                className={classes.textField}
                onChange={this.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }} />
            </Grid>

            <Grid item md={2} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.tuesdayActive || false}
                    name="tuesdayActive"
                    onChange={this.handleCheck}
                    color="primary"
                  />
                }
                label="Tuesday"
                labelPlacement="end"
              />
            </Grid>

            <Grid item md={5} xs={12}>
              <TextField
                variant="standard"
                id="tuesdayStart"
                fullWidth
                label="Start Time"
                type="time"
                name="tuesdayStart"
                value={this.state.tuesdayStart || ''}
                className={classes.textField}
                onChange={this.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }} />
            </Grid>
            <Grid item md={5} xs={12}>
              <TextField
                variant="standard"
                id="tuesdayEnd"
                fullWidth
                label="End Time"
                type="time"
                name="tuesdayEnd"
                value={this.state.tuesdayEnd || ''}
                className={classes.textField}
                onChange={this.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }} />
            </Grid>

            <Grid item md={2} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.wednesdayActive || false}
                    name="wednesdayActive"
                    onChange={this.handleCheck}
                    color="primary"
                  />
                }
                label="Wednesday"
                labelPlacement="end"
              />
            </Grid>

            <Grid item md={5} xs={12}>
              <TextField
                variant="standard"
                id="wednesdayStart"
                fullWidth
                label="Start Time"
                type="time"
                name="wednesdayStart"
                value={this.state.wednesdayStart || ''}
                className={classes.textField}
                onChange={this.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }} />
            </Grid>
            <Grid item md={5} xs={12}>
              <TextField
                variant="standard"
                id="wednesdayEnd"
                fullWidth
                label="End Time"
                type="time"
                name="wednesdayEnd"
                value={this.state.wednesdayEnd || ''}
                className={classes.textField}
                onChange={this.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }} />
            </Grid>

            <Grid item md={2} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.thursdayActive || false}
                    name="thursdayActive"
                    onChange={this.handleCheck}
                    color="primary"
                  />
                }
                label="Thursday"
                labelPlacement="end"
              />
            </Grid>

            <Grid item md={5} xs={12}>
              <TextField
                variant="standard"
                id="thursdayStart"
                fullWidth
                label="Start Time"
                type="time"
                name="thursdayStart"
                value={this.state.thursdayStart || ''}
                className={classes.textField}
                onChange={this.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }} />
            </Grid>
            <Grid item md={5} xs={12}>
              <TextField
                variant="standard"
                id="thursdayEnd"
                fullWidth
                label="End Time"
                type="time"
                name="thursdayEnd"
                value={this.state.thursdayEnd || ''}
                className={classes.textField}
                onChange={this.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }} />
            </Grid>

            <Grid item md={2} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.fridayActive || false}
                    name="fridayActive"
                    onChange={this.handleCheck}
                    color="primary"
                  />
                }
                label="Friday"
                labelPlacement="end"
              />
            </Grid>

            <Grid item md={5} xs={12}>
              <TextField
                variant="standard"
                id="fridayStart"
                fullWidth
                label="Start Time"
                type="time"
                name="fridayStart"
                value={this.state.fridayStart || ''}
                className={classes.textField}
                onChange={this.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }} />
            </Grid>
            <Grid item md={5} xs={12}>
              <TextField
                variant="standard"
                id="fridayEnd"
                fullWidth
                label="End Time"
                type="time"
                name="fridayEnd"
                value={this.state.fridayEnd || ''}
                className={classes.textField}
                onChange={this.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }} />
            </Grid>

            <Grid item md={2} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.saturdayActive || false}
                    name="saturdayActive"
                    onChange={this.handleCheck}
                    color="primary"
                  />
                }
                label="Saturday"
                labelPlacement="end"
              />
            </Grid>

            <Grid item md={5} xs={12}>
              <TextField
                variant="standard"
                id="saturdayStart"
                fullWidth
                label="Start Time"
                type="time"
                name="saturdayStart"
                value={this.state.saturdayStart || ''}
                className={classes.textField}
                onChange={this.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }} />
            </Grid>
            <Grid item md={5} xs={12}>
              <TextField
                variant="standard"
                id="saturdayEnd"
                fullWidth
                label="End Time"
                type="time"
                name="saturdayEnd"
                value={this.state.saturdayEnd || ''}
                className={classes.textField}
                onChange={this.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" type="submit" onClick={this.handleClose}>
            Close
          </Button>

          <Button
            color="primary"
            variant="contained"
            type="submit"
            onClick={this.updateFormValues}
            disabled={this.state.buttonLoading}
          >
            Save details
          </Button>
        </DialogActions>
      </Dialog>
    </>;
  }
}

export default Store.withStore(withStyles(styles)(EditTeamMember));
