import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
// replace imports from `@mui/material` to `@mui/material` - mui updated branding
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: this.props.address,
      errorMessage: '',
      latitude: null,
      longitude: null,
      isGeocoding: false,
    };
  }

  handleChange = (address) => {
    this.props.handleAddressChange(address, null);
    this.setState({
      address,
      latitude: null,
      longitude: null,
      errorMessage: '',
    });
  };

  handleSelect = (selected) => {
    this.setState({ isGeocoding: true, address: selected });
    geocodeByAddress(selected)
      .then((res) => getLatLng(res[0]))
      .then(({ lat, lng }) => {
        this.props.handleAddressChange(selected, {
          latitude: lat,
          longitude: lng,
        });
        this.setState({
          latitude: lat,
          longitude: lng,
          isGeocoding: false,
        });
      })
      .catch((error) => {
        this.setState({ isGeocoding: false });
        console.log('error', error); // eslint-disable-line no-console
      });
  };

  handleCloseClick = () => {
    this.setState({
      address: '',
      latitude: null,
      longitude: null,
    });
  };

  handleError = (status, clearSuggestions) => {
    console.log('Error from Google Maps API', status); // eslint-disable-line no-console
    this.setState({ errorMessage: status }, () => {
      clearSuggestions();
    });
  };

  render() {
    const { address, errorMessage } = this.state;
    const isObject = (val) => {
      return typeof val === 'object' && val !== null;
    };

    const classnames = (...args) => {
      const classes = [];
      args.forEach((arg) => {
        if (typeof arg === 'string') {
          classes.push(arg);
        } else if (isObject(arg)) {
          Object.keys(arg).forEach((key) => {
            if (arg[key]) {
              classes.push(key);
            }
          });
        } else {
          throw new Error(
            '`classnames` only accepts string or object as arguments',
          );
        }
      });

      return classes.join(' ');
    };
    return (
      <div>
        <PlacesAutocomplete
          onChange={this.handleChange}
          value={this.props.address}
          onSelect={this.handleSelect}
          onError={this.handleError}
          shouldFetchSuggestions={address.length > 2}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => {
            return (
              <div className="search-bar-container">
                <div className="search-input-container">
                  <TextField
                    style={{ width: '100%', backgroundColor: '#fff' }}
                    required
                    variant="outlined"
                    {...getInputProps({
                      placeholder: 'Custom Location...',
                      id: 'input-addres-type-ahead',
                    })}
                    label="Custom Location"
                    error={
                      this.props.showError && this.props.address === ""
                    }
                    onChange={this.onLocationChange}
                  />
                  {suggestions.length > 0 && (
                    <button
                      className="clear-button"
                      onClick={this.handleCloseClick}
                    >
                      x
                    </button>
                  )}
                </div>
                {suggestions.length > 0 && (
                  <div className="autocomplete-container">
                    {suggestions.map((suggestion) => {
                      const className = classnames('suggestion-item', {
                        'suggestion-item--active': suggestion.active,
                      });

                      return (
                        /* eslint-disable react/jsx-key */
                        <div
                          {...getSuggestionItemProps(suggestion, { className })}
                        >
                          <strong>
                            {suggestion.formattedSuggestion.mainText}
                          </strong>{' '}
                          <small>
                            {suggestion.formattedSuggestion.secondaryText}
                          </small>
                        </div>
                      );
                      /* eslint-enable react/jsx-key */
                    })}
                  </div>
                )}
              </div>
            );
          }}
        </PlacesAutocomplete>
        {errorMessage.length > 0 && (
          <div className="error-message">{this.state.errorMessage}</div>
        )}
      </div>
    );
  }
}

export default LocationSearchInput;
