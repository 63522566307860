import React from 'react';
// replace imports from `@mui/*` to `@mui/*` - mui updated branding
import Dialog from '@mui/material/Dialog';

class PhotoModal extends React.Component<Props, State> {
  constructor(props: Props): void {
    super(props);
    this.state = {
      open: false,
    };
  }
  handleOpen = (e) => {
    this.setState({
      open: true,
    });
  };

  handleClose = (e) => {
    this.setState({
      open: false,
    });
  };

  render(): Node {
    const { open } = this.state;

    return (
      <>
        <img
          onClick={this.handleOpen}
          alt=""
          height="75"
          src={this.props.rubbishPhotoURL}
        />
        <Dialog
          fullWidth
          maxWidth="lg"
          title="Edit Code"
          open={open}
          onClose={this.handleClose}
        >
          <div onClick={this.handleClose} className="text-center">
            <img
              alt="rubbishurl"
              style={{ width: '60%' }}
              src={this.props.rubbishPhotoURL}
            />
          </div>
        </Dialog>
      </>
    );
  }
}

export default PhotoModal;
