import { createConnectedStore, Effect } from 'undux';
import { withEffects } from './effects';

// Declare your store's types.
type State = {
  buttonText: string,
  clickCount: number,
  user: object,
  teamList: array,
  currentTeam: string,
  currentTeamDisplayName: string,
}

// Declare your store's initial state.
const initialState: State = {
  buttonText: 'Click Me',
  clickCount: 0,
  user: {authenticated: false},
  teamList: [],
  currentTeam: (window.localStorage.getItem('currentTeam') ? window.localStorage.getItem('currentTeam') : "0"),
  currentTeamDisplayName:  (window.localStorage.getItem('currentTeamDisplayName') ? window.localStorage.getItem('currentTeamDisplayName') : ""),
}

// Create & export a store with an initial value.
export default createConnectedStore(initialState, withEffects);

// Export prop types for React.
export type StoreProps = {
  store: typeof store
}

// Export a concrete Effect type for effects.
export type StoreEffect = Effect<State>;