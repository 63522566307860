import React from 'react';

function Footer() {
  return (
    <footer style={{paddingTop: 30}} className="pt-5">
      <div className="container text-center">
        <p>&copy; Rubbish {new Date().getFullYear()}.</p>
      </div>
    </footer>
  )
}

export default Footer;