import React, { Component } from 'react';
// to-do: fix - migration from v4-v5 messed up spacing and switch styling, tabs styling
import {
  Button,
  Grid,
  TextField,
  IconButton,
  OutlinedInput,
  Divider,
  InputLabel,
  Select,
  Switch,
  Card,
  MenuItem,
  CardHeader,
  CardContent,
  ListItemSecondaryAction,
  Badge,
} from '@mui/material';
import Store from '../../services/Store';
import axios from '../../helpers/axios';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import Chip from '@mui/material/Chip';
import Tab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import CameraAltIcon from '@material-ui/icons/CameraAlt';

import { storage } from '../../services/firebase';
var QRCode = require('qrcode');

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={'simple-tabpanel-${index}'}
      aria-labelledby={'simple-tab-${index}'}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

class PhotoModal extends React.Component<Props, State> {
  constructor(props: Props): void {
    super(props);
    this.state = {
      open: false,
    };
  }
  handleOpen = (e) => {
    this.setState({
      open: true,
    });
  };

  handleClose = (e) => {
    this.setState({
      open: false,
    });
  };

  render(): Node {
    const { open } = this.state;
    var getUrl = window.location;
    let qrurl =
      getUrl.protocol + '//' + getUrl.host + '/c/' + this.props.qrCodeID;
    let displayUrl;
    QRCode.toDataURL(qrurl, { type: 'svg' }, function (err, url) {
      displayUrl = url;
    });
    return (
      <>
        <img
          onClick={this.handleOpen}
          alt=""
          height="45"
          width="45"
          style={{ top: 10, right: 20, position: 'absolute' }}
          src={displayUrl}
        />
        <Dialog
          fullWidth
          maxWidth="lg"
          title="Edit Code"
          open={open}
          onClose={this.handleClose}
        >
          <div onClick={this.handleClose} className="text-center">
            <img alt="rubbishurl" style={{ width: '60%' }} src={displayUrl} />
          </div>
        </Dialog>
      </>
    );
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const styles = (theme) => ({
  buttonProperty: {
    position: 'absolute',
    top: '50%',
  },
  appBar: {
    backgroundColor: '#AAA',
  },
  uiProgess: {
    position: 'fixed',
    zIndex: '1000',
    height: '31px',
    width: '31px',
    left: '50%',
    top: '35%',
  },
  progess: {
    position: 'absolute',
  },
  status: {
    marginLeft: '15px',
  },
  uploadButton: {
    marginLeft: '8px',
    margin: theme.spacing(1),
  },
  customError: {
    color: 'red',
    fontSize: '0.8rem',
    marginTop: 10,
  },
  saveRight: {
    marginLeft: 'auto',
    marginTop: '10px',
    float: 'right',
  },
  fileUpload: {
    '& input': {
      height: 'unset',
    },
  },

  action: {
    width: '500px',
  },

  gridContainer: {
    flexGrow: 1,
  },
  noFlex: {
    display: 'block',
  },
  selectControl: {
    width: '100%',
    textAlign: 'left',
  },
  paper: {
    position: 'absolute',
    width: '60%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 16,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
});

const MenuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
}
class EditCode extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      uiLoading: true,

      savingSpinner: false,
      modalOpen: false,
      code: {},
      tabValue: 0,
      modalTypeOpen: false,
      newSelectRubbishType: '',
      teamDisplayName: '',
      teamMembers: [],
      newTeamMemberID: '',
      newCodeName: '',
      inactiveCodes: [],
      copyToCode: '',
      urlSlugError: false,
    };
  }
  componentDidMount = () => {
    this._isMounted = true;
    const { store, codeID } = this.props;
    const authToken = store.get('user').accessToken;
    const currentTeam = store.get('currentTeam');
    axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
    axios
      .get(`/api/codes/${codeID}`)
      .then((response) => {
        let code = response.data.qrData;
        let subscriberIDS = [];
        code.subscribers.forEach((sub) => {
          subscriberIDS.push(sub.userID);
        });

        let teamMembers = this.props.teamMembers.filter(
          (mem) => !subscriberIDS.includes(mem.userID),
        );
        code.rubbishTypeIDS = code.rubbishTypeIDS || [];
        if (this._isMounted) {
          this.setState({
            rubbishTypes: response.data.rubbishTypes,
            teamDisplayName: code.team.teamDisplayName,
            tabValue: 0,
            teamMembers: teamMembers,
            subscribers: code.subscribers,
            code: code,
            description: code.description,
            identifier: code.identifier,
            currentUrlSlug: code.urlSlug || '',
            urlSlug: code.urlSlug || '',
            zone: code.zone,
            stickerType: code.stickerType,
            stickerFixture: code.stickerFixture,
            location: code.location,
            lat: code.lat,
            long: code.long,
            artDescription: code.artDescription,
            artTitle: code.artTitle,
            artistName: code.artistName,
            artistLink: code.artistLink,
            artistLinkText: code.artistLinkText,
            artistCaptionPreface:
              code.artistCaptionPreface && code.artistCaptionPreface.length > 0
                ? code.artistCaptionPreface
                : 'Artwork By',
            artistActionText:
              code.artistActionText && code.artistActionText.length > 0
                ? code.artistActionText
                : 'View full artwork and more about the artist',
            newSelectRubbishType: '',
            enableLocationPicker: code.enableLocationPicker || false,
            skipLanding: code.skipLanding || false,
            uiLoading: false,
            modalOpen: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        if (this._isMounted) {
          this.setState({
            buttonLoading: false,
          });
        }
      });
    axios
      .get(`/api/codes?inactive=true&teamID=${currentTeam}`)
      .then((response) => {
        // console.log(response);
        this.setState({
          inactiveCodes: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    this.setState({ modalOpen: true });
  };
  componentWillUnmount() {
    if (this._isMounted) this._isMounted = false;
  }
  handleOpenType = () => {
    this.setState({ modalTypeOpen: true });
  };
  handleCloseType = () => {
    this.setState({ modalTypeOpen: false });
  };
  handleClose = () => {
    this.props.handleEditClose();
  };
  handleToastClose = () => {
    this.setState({ toastOpen: false });
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleCheck = (event) => {
    if (event.target.checked) {
      this.setState({ [event.target.name]: true });
    } else {
      this.setState({ [event.target.name]: false });
    }
  };
  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };
  onSelectType = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  formatToPhone = (event) => {
    // I am lazy and don't like to type things more than once
    const target = event.target;
    const input = target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    if (input.length > 6) {
      target.value = `(${zip}) ${middle} - ${last}`;
    } else if (input.length > 3) {
      target.value = `(${zip}) ${middle}`;
    } else if (input.length > 0) {
      target.value = `(${zip}`;
    }
    this.setState({ [event.target.name]: event.target.value });
  };
  updateFormValues = () => {
    const { store, codeID } = this.props;
    const authToken = store.get('user').accessToken;
    const currentTeam = store.get('currentTeam');
    var formData = new FormData();
    this.setState({ savingSpinner: true });
    const formRequest = {
      description: this.state.description,
      identifier: this.state.identifier,
      urlSlug: this.state.urlSlug,
      zone: this.state.zone,
      stickerType: this.state.stickerType,
      stickerFixture: this.state.stickerFixture,
      location: this.state.location,
      enableLocationPicker: this.state.enableLocationPicker,
      skipLanding: this.state.skipLanding,
      lat: this.state.lat,
      long: this.state.long,
      artDescription: this.state.artDescription,
      artTitle: this.state.artTitle,
      artistName: this.state.artistName,
      artistLink: this.state.artistLink,
      artistLinkText: this.state.artistLinkText,
      artistCaptionPreface: this.state.artistCaptionPreface,
      artistActionText: this.state.artistActionText,
    };
    window.this = this;
    axios
      .post(`/api/codes/${codeID}`, formRequest, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(function (response) {
        if (response.data.slugChanged) {
          formRequest.urlSlug = response.data.data.urlSlug;
          window.this.setState({
            urlSlug: response.data.data.urlSlug,
            urlSlugError: true,
            currentUrlSlug: response.data.data.urlSlug,
          });
        } else {
          window.this.setState({
            urlSlugError: false,
          });
        }
        window.this.props.updateCode(formRequest);
        window.this.setState({ savingSpinner: false });
        // console.log('SUCCESS!!');
      });
  };
  saveGalleryImage = (imgUrl, landing = false) => {
    const { store, codeID } = this.props;
    const authToken = store.get('user').accessToken;
    const currentTeam = store.get('currentTeam');
    var formData = new FormData();

    const formRequest = {
      galleryImage: imgUrl,
      landing: landing,
    };
    axios
      .post(`/api/codes/${codeID}`, formRequest, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(function () {
        // console.log('SUCCESS!!');
      })
      .catch(function () {
        console.log('failed to save image');
      });
  };
  addToGallery = () => {
    const { code } = this.state;
    const file = document.querySelector('#qrCodeGallery').files[0];
    if (file) {
      // const file = $('#rubbishPhoto').get(0).files[0];
      const metadata = { contentType: file.type };
      const ref = storage().ref();
      const name = +new Date() + '-' + file.name;
      const task = ref
        .child('QrCodeArtGallery/' + this.state.code.qrCodeID + '/' + name)
        .put(file, metadata);
      task
        .then((snapshot) => snapshot.ref.getDownloadURL())
        .then((url) => {
          document.querySelector('#qrCodeGallery').value = '';
          this.saveGalleryImage(
            'QrCodeArtGallery/' + this.state.code.qrCodeID + '/' + name,
          );
          if (code.artGallery === undefined) {
            code.artGallery = [];
          }
          // console.log(url);
          // console.log('sett ittt')
          code.artGallery.push({
            signed: url,
            path: 'QrCodeArtGallery/' + this.state.code.qrCodeID + '/' + name,
          });
          this.setState({ code: code });
        })
        .catch(console.error);
    }
  };
  triggerPhoto = (e) => {
    e.preventDefault();
    document.getElementById('qrCodeGallery').click();
  };
  triggerLandingArt = (e) => {
    e.preventDefault();
    document.getElementById('qrCodeArt').click();
  }
  addLandingArt = () => {
    const { code } = this.state;
    const file = document.querySelector('#qrCodeArt').files[0];
    if (file) {
      // const file = $('#rubbishPhoto').get(0).files[0];
      const metadata = { contentType: file.type };
      const ref = storage().ref();
      const name = +new Date() + '-' + file.name;
      const task = ref
        .child('QrCodeArt/' + this.state.code.qrCodeID + '/' + name)
        .put(file, metadata);
      task
        .then((snapshot) => snapshot.ref.getDownloadURL())
        .then((url) => {
          document.querySelector('#qrCodeArt').value = '';
          this.saveGalleryImage(
            'QrCodeArt/' + this.state.code.qrCodeID + '/' + name,
            true,
          );

          code.artImageURL = url;
          this.setState({ code: code });
        })
        .catch(console.error);
    }
  };

  addRubbishType = () => {
    const { newSelectRubbishType, code } = this.state;

    const { store } = this.props;
    const authToken = store.get('user').accessToken;
    const team = store.get('currentTeam');
    if (newSelectRubbishType) {
      axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
      let formRequest = {
        rubbishTypeID: newSelectRubbishType,
      };
      axios
        .post(`/api/codes/${code.qrCodeID}/add_type`, formRequest)
        .then((teamResponse) => {
          code.rubbishTypeIDS.push(newSelectRubbishType);
          this.setState({ code: code, newSelectRubbishType: '' });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            buttonLoading: false,
          });
        });
    }
  };
  addSubscriber = () => {
    const { newTeamMemberID, teamMembers, subscribers, code } = this.state;

    const { store } = this.props;
    const authToken = store.get('user').accessToken;
    const team = store.get('currentTeam');

    axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
    let formRequest = {
      teamMemberID: newTeamMemberID,
    };
    if (newTeamMemberID) {
      window.this = this;
      axios
        .post(`/api/codes/${code.qrCodeID}/add_subscriber`, formRequest)
        .then((response) => {
          let memberIndex = teamMembers.findIndex(
            (obj) => obj.userID == newTeamMemberID,
          );
          subscribers.push(teamMembers[memberIndex]);
          let subIndex = subscribers.findIndex(
            (obj) => obj.userID == newTeamMemberID,
          );
          subscribers[subIndex].qrCodeSubscriberID =
            response.data.qrCodeSubscriberID;
          let newteamMembers = teamMembers.filter(
            (item) => item.userID !== newTeamMemberID,
          );
          window.this.setState({
            subscribers: subscribers,
            teamMembers: newteamMembers,
            newTeamMemberID: '',
          });
        })
        .catch((error) => {
          console.log(error);
          window.this.setState({
            buttonLoading: false,
          });
        });
    }
  };
  cloneCode = () => {
    const { newCodeName, copyToCode, code } = this.state;

    const { store } = this.props;
    const authToken = store.get('user').accessToken;
    const team = store.get('currentTeam');

    axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
    let formRequest = {
      newCodeName: newCodeName,
    };
    if (copyToCode.length > 0) {
      formRequest['copyToCode'] = copyToCode;
    }
    window.this = this;
    axios
      .post(`/api/codes/${code.qrCodeID}/clone`, formRequest)
      .then((response) => {
        let responseData = response.data;
        let updateCodeId = response.data.codeID;
        if (formRequest['copyToCode']) {
          updateCodeId = formRequest['copyToCode'];
        }

        responseData['id'] = updateCodeId;
        responseData['codeID'] = updateCodeId;
        window.this.props.updateCode(responseData, updateCodeId);
        window.this.setState({ newCodeName: '', copyToCode: '' });
      })
      .catch((error) => {
        console.log(error);
        window.this.setState({
          buttonLoading: false,
        });
      });
  };
  removeRubbishType = (rubbishTypeID, rubbishTypeName) => {
    if (
      window.confirm(
        "Are you sure you'd like to remove " + rubbishTypeName + '?',
      )
    ) {
      const { store } = this.props;
      const { code } = this.state;
      const authToken = store.get('user').accessToken;
      axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
      axios
        .delete(`/api/codes/${code.qrCodeID}/remove_type/${rubbishTypeID}`)
        .then((teamResponse) => {
          code.rubbishTypeIDS = code.rubbishTypeIDS.filter(
            (item) => item !== rubbishTypeID,
          );

          this.setState({ code: code });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            buttonLoading: false,
          });
        });
    }
  };

  removeSubscriber = (subscriberID, subscriberName) => {
    if (
      window.confirm(
        "Are you sure you'd like to remove " + subscriberName + '?',
      )
    ) {
      const { store } = this.props;
      const { code, teamMembers, subscribers } = this.state;
      const authToken = store.get('user').accessToken;
      window.this = this;
      axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
      axios
        .delete(`/api/codes/${code.qrCodeID}/remove_subscriber/${subscriberID}`)
        .then((teamResponse) => {
          let memberIndex = subscribers.findIndex(
            (obj) => obj.qrCodeSubscriberID == subscriberID,
          );

          teamMembers.push(subscribers[memberIndex]);
          let newSubscribers = subscribers.filter(
            (item) => item.qrCodeSubscriberID !== subscriberID,
          );
          window.this.setState({
            subscribers: newSubscribers,
            teamMembers: teamMembers,
            newTeamMemberID: '',
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            buttonLoading: false,
          });
        });
    }
  };
  handleRemoveImage = (imagePath) => {
    if (
      window.confirm(
        "Are you sure you'd like to remove the image at " + imagePath + '?',
      )
    ) {
      const { store } = this.props;
      const { code } = this.state;
      const authToken = store.get('user').accessToken;
      axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
      let formRequest = {
        imgPath: imagePath,
      };
      axios
        .post(`/api/codes/${code.qrCodeID}/remove_gallery_img`, formRequest)
        .then((codeResponse) => {
          Object.keys(code.artGallery).map((i) => {
            if (
              code.artGallery[i] !== undefined &&
              code.artGallery[i].path == imagePath
            ) {
              code.artGallery.splice(i, 1);
            }
          });

          this.setState({ code: code });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            buttonLoading: false,
          });
        });
    }
  };
  handleRemovePrimary = (imagePath) => {
    if (
      window.confirm("Are you sure you'd like to remove the primary image?")
    ) {
      const { store } = this.props;
      const { code } = this.state;
      const authToken = store.get('user').accessToken;
      axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
      let formRequest = {
        imgPath: 'primary',
      };
      axios
        .post(`/api/codes/${code.qrCodeID}/remove_gallery_img`, formRequest)
        .then((codeResponse) => {
          code.artImageURL = '';
          this.setState({ code: code });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            buttonLoading: false,
          });
        });
    }
  };
  toggleActive = () => {
    const { store, codeID } = this.props;
    const authToken = store.get('user').accessToken;
    const currentTeam = store.get('currentTeam');
    var formData = new FormData();

    const formRequest = {
      isActive: !this.state.code.isActive,
    };
    window.this = this;
    axios
      .post(`/api/codes/${codeID}`, formRequest, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(
        function () {
          let code = this.state.code;
          code.isActive = !code.isActive;
          window.this.setState({ code: code });
          window.this.props.updateCode(formRequest);
        }.bind(this),
      );
  };
  toggleArt = () => {
    const { store, codeID } = this.props;
    const authToken = store.get('user').accessToken;
    const currentTeam = store.get('currentTeam');
    var formData = new FormData();

    const formRequest = {
      disableMoreArt: !this.state.code.disableMoreArt,
    };
    window.this = this;
    axios
      .post(`/api/codes/${codeID}`, formRequest, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(
        function () {
          let code = this.state.code;
          code.disableMoreArt = !code.disableMoreArt;
          window.this.setState({ code: code });
          window.this.props.updateCode(formRequest);
        }.bind(this),
      );
  };
  toggleLocationPicker = () => {
    this.setState({ enableLocationPicker: !this.state.enableLocationPicker });
  };
  toggleSkipLanding = () => {
    this.setState({skipLanding: !this.state.skipLanding})
  }
  render() {
    const { codeID, classes } = this.props;
    const { modalOpen, code, tabValue, modalTypeOpen } = this.state;

    let modalStyle = {
      top: `45%`,
      left: `50%`,
      transform: `translate(-45%, -50%)`,
    };
    let primaryImage = null;
    if (code.artGallery === undefined) {
      code.artGallery = [];
    }
    if (code.artImageURL !== undefined && code.artImageURL !== '') {
      primaryImage = (
        <>
          <Badge
            overlap="rectangular"
            badgeContent={
              <IconButton
                size="small"
                aria-label="delete"
                onClick={() => this.handleRemovePrimary()}
              >
                <DeleteIcon color="error" />
              </IconButton>
            }
          >
            <img
              className="img-thumbnail"
              alt="primary"
              src={code.artImageURL}
            />
          </Badge>
        </>
      );
    }

    if (this.state.uiLoading === true) {
      return (
        <>
          {this.state.uiLoading && (
            <CircularProgress size={150} className={classes.uiProgess} />
          )}
        </>
      );
    } else {
      let codeRubbishTypeIDS = [];
      if (code.rubbishTypeIDS) {
        codeRubbishTypeIDS = code.rubbishTypeIDS;
      }
      var getUrl = window.location;
      return <>
        <Dialog
          fullWidth
          maxWidth="md"
          open={modalOpen}
          onClose={this.handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">
            {code.description}
            {/* <Chip label={code.status} color="primary" />{' '} */}
          </DialogTitle>

          <FormGroup style={{ top: 10, right: 60, position: 'absolute' }}>
            <FormControlLabel
              control={
                <Switch
                  checked={
                    this.state.code.isActive == true ||
                    this.state.isActive == 'True'
                      ? true
                      : false
                  }
                  onChange={() => this.toggleActive()}
                />
              }
              label="Active"
            />
          </FormGroup>

          <PhotoModal className="pull-right" qrCodeID={code.qrCodeID} />
          <DialogContent>
            <p>
              <>
                Preview:{' '}
                <a
                  target="_blank"
                  href={
                    getUrl.protocol +
                    '//' +
                    getUrl.host +
                    '/c/' +
                    code.qrCodeID
                  }
                >
                  {getUrl.protocol +
                    '//' +
                    getUrl.host +
                    '/c/' +
                    code.qrCodeID}
                </a>
              </>
              <br />
              {this.state.currentUrlSlug.length > 0 ? (
                <>
                  Readable Link:{' '}
                  <a
                    target="_blank"
                    href={
                      getUrl.protocol +
                      '//' +
                      getUrl.host +
                      '/s/' +
                      this.state.currentUrlSlug
                    }
                  >
                    {getUrl.protocol +
                      '//' +
                      getUrl.host +
                      '/s/' +
                      this.state.currentUrlSlug}
                  </a>
                </>
              ) : (
                ''
              )}
            </p>
            <AppBar className={classes.appBar} position="static">
              <Tabs
                value={tabValue}
                onChange={this.handleTabChange}
                aria-label="Edit Code"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="Top Info" {...a11yProps(0)} />
                <Tab label="Bottom Art" {...a11yProps(1)} />
                <Tab label="Issue Types" {...a11yProps(2)} />
                <Tab label="Subscribers" {...a11yProps(3)} />
                <Tab label="Tools" {...a11yProps(4)} />
              </Tabs>
            </AppBar>

            <TabPanel value={tabValue} index={0}>
              <Grid container spacing={1}>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    margin="dense"
                    variant="outlined"
                    name="description"
                    value={this.state.description || ''}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={6}>
                  <TextField
                    fullWidth
                    label="Subtitle"
                    margin="dense"
                    name="artistCaptionPreface"
                    variant="outlined"
                    value={this.state.artistCaptionPreface || ''}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={6}>
                  <TextField
                    fullWidth
                    label="Subtitle Bold"
                    margin="dense"
                    name="artistName"
                    variant="outlined"
                    value={this.state.artistName || ''}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Call to Action Text"
                    margin="dense"
                    name="artistActionText"
                    variant="outlined"
                    value={this.state.artistActionText || ''}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Team"
                    margin="dense"
                    name="teamID"
                    variant="outlined"
                    disabled
                    value={
                      this.state.teamDisplayName + ' (' + code.teamID + ')' ||
                      ''
                    }
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Identifier"
                    margin="dense"
                    name="identifier"
                    variant="outlined"
                    value={this.state.identifier || ''}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Zone"
                    margin="dense"
                    name="zone"
                    variant="outlined"
                    value={this.state.zone || ''}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Location"
                    margin="dense"
                    name="location"
                    variant="outlined"
                    value={this.state.location || ''}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Sticker Type"
                    margin="dense"
                    name="stickerType"
                    variant="outlined"
                    value={this.state.stickerType || ''}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Sticker Fixture"
                    margin="dense"
                    name="stickerFixture"
                    variant="outlined"
                    value={this.state.stickerFixture || ''}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            this.state.enableLocationPicker == true ||
                            this.state.enableLocationPicker == 'True'
                              ? true
                              : false
                          }
                          onChange={() => this.toggleLocationPicker()}
                        />
                      }
                      label="Enable Location Picker"
                    />
                  </FormGroup>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Readable URL Identifier"
                    margin="dense"
                    name="urlSlug"
                    variant="outlined"
                    error={this.state.urlSlugError || false}
                    helperText={
                      this.state.urlSlugError
                        ? 'Requested Identifier unavailable.'
                        : ''
                    }
                    value={this.state.urlSlug || ''}
                    onChange={this.handleChange}
                  />
                </Grid>
                {this.state.enableLocationPicker == false ? (
                  <>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Latitude"
                        margin="dense"
                        name="lat"
                        variant="outlined"
                        value={this.state.lat || ''}
                        onChange={this.handleChange}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Longitude"
                        margin="dense"
                        name="long"
                        variant="outlined"
                        value={this.state.long || ''}
                        onChange={this.handleChange}
                      />
                    </Grid>
                  </>
                ) : (
                  ''
                )}
                <Grid
                  className={classes.noFlex}
                  item
                  container
                  xs={12}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            this.state.skipLanding == true ||
                            this.state.skipLanding == 'True'
                              ? true
                              : false
                          }
                          onChange={() => this.toggleSkipLanding()}
                        />
                      }
                      label="Skip Landing Page"
                    />
                  </FormGroup>
                </Grid>
                {this.state.skipLanding == false ? (
                  <>
                    <Grid
                      className={classes.noFlex}
                      item
                      container
                      md={6}
                      xs={12}
                    >
                      <Grid style={{ marginBottom: '10px' }} item xs={12}>
                        <h4>Landing Image</h4>
                      </Grid>
                      <Grid style={{ marginBottom: '15px' }} item xs={12}>
                        {/* <OutlinedInput
                          className={classes.fileUpload}
                          accept="image/*"
                          fullWidth
                          variant="outlined"
                          id="qrCodeArt"
                          onChange={this.addLandingArt}
                          type="file"
                        /> */}
                        <Button
                          className={classes.button}
                          fullWidth
                          variant="outlined"
                          size="large"
                          endIcon={<CameraAltIcon />}
                          onClick={this.triggerLandingArt}
                        >
                          Upload Landing Image
                        </Button>
                        <input
                          onChange={this.addLandingArt}
                          id="qrCodeArt"
                          name="qrCodeArt"
                          type="file"
                          accept="image/*"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      className={classes.noFlex}
                      item
                      container
                      md={6}
                      xs={12}
                    >
                      <Grid style={{ marginBottom: '10px' }} item xs={12}>
                        <Grid item md={4} xs={4}>
                          {primaryImage}
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : ''}
              </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Grid container spacing={1}>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Title"
                    margin="dense"
                    name="artTitle"
                    variant="outlined"
                    value={this.state.artTitle || ''}
                    onChange={this.handleChange}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    rows={3}
                    multiline
                    label="Description"
                    margin="dense"
                    variant="outlined"
                    name="artDescription"
                    value={this.state.artDescription || ''}
                    onChange={this.handleChange}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Link"
                    margin="dense"
                    name="artistLink"
                    variant="outlined"
                    value={this.state.artistLink || ''}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Link Text"
                    margin="dense"
                    name="artistLinkText"
                    variant="outlined"
                    value={this.state.artistLinkText || ''}
                    onChange={this.handleChange}
                  />
                </Grid>

                <Grid
                  className={classes.noFlex}
                  item
                  container
                  md={6}
                  xs={12}
                >
                  <Grid style={{ marginBottom: '10px' }} item xs={12}>
                    <h4>Additional Images</h4>
                  </Grid>
                  <Grid style={{ marginBottom: '15px' }} item xs={12}>
                    {/* <OutlinedInput
                      className={classes.fileUpload}
                      accept="image/*"
                      fullWidth
                      variant="outlined"
                      id="qrCodeGallery"
                      onChange={this.addToGallery}
                      type="file"
                    /> */}
                      <Button
                        className={classes.button}
                        fullWidth
                        variant="outlined"
                        size="large"
                        endIcon={<CameraAltIcon />}
                        onClick={this.triggerPhoto}
                      >
                        Upload Additional Images
                      </Button>
                      <input
                        onChange={this.addToGallery}
                        id="qrCodeGallery"
                        name="qrCodeGallery"
                        type="file"
                        accept="image/*"
                      />
                  </Grid>
                </Grid>
                <Grid
                  className={classes.noFlex}
                  item
                  container
                  md={6}
                  xs={12}
                >
                  <Grid
                    style={{ marginBottom: '10px' }}
                    item
                    container
                    spacing={1}
                  >
                    {Object.keys(code.artGallery).map((key) => {
                      const url = code.artGallery[key]['signed'];
                      const path = code.artGallery[key]['path'];
                      if (url) {
                        return (
                          <Grid key={key} item md={4} xs={4}>
                            <Badge
                              overlap="rectangular"
                              badgeContent={
                                <IconButton
                                  size="small"
                                  aria-label="delete"
                                  onClick={() => this.handleRemoveImage(path)}
                                >
                                  <DeleteIcon color="error" />
                                </IconButton>
                              }
                            >
                              <img
                                alt={key}
                                className="img-thumbnail"
                                src={url}
                              />
                            </Badge>
                          </Grid>
                        );
                      } else {
                        return '';
                      }
                    })}
                  </Grid>
                </Grid>
                <Grid className={classes.gridContainer} item md={12} xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            this.state.code.disableMoreArt == true ||
                            this.state.disableMoreArt == 'True'
                              ? true
                              : false
                          }
                          onChange={() => this.toggleArt()}
                        />
                      }
                      label="Disable Art"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <Card className={classes.gridContainer}>
                <CardHeader title="Issue Types"></CardHeader>
                <CardContent>
                  <Grid container>
                    <Grid item md={10} xs={10}>
                      <FormControl variant="outlined" className={classes.selectControl}>
                        <InputLabel id="newSelectRubbishTypeLabel">
                          Select Rubbish Type...
                        </InputLabel>
                        <Select
                          fullWidth
                          value={this.state.newSelectRubbishType || ''}
                          name="newSelectRubbishType"
                          label="Select issue Type..."
                          id="newSelectRubbishType"
                          onChange={this.onSelectType}
                        >
                          <MenuItem key={0} value="">
                            Select Rubbish type...
                          </MenuItem>
                          {this.state.code.possibleRubbishTypeIDS.map((key) => {
                            const s = this.state.rubbishTypes[key];
                            if (!codeRubbishTypeIDS.includes(key)) {
                              return (
                                <MenuItem key={key} value={key}>
                                  {s}
                                </MenuItem>
                              );
                            }
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={2} xs={2}>
                      <IconButton onClick={this.addRubbishType} aria-label="settings" size="large">
                        <AddCircleIcon fontSize="large" color="primary" />
                      </IconButton>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <List>
                        {codeRubbishTypeIDS.map((key) => {
                          return (
                            <ListItem key={key} alignItems="flex-start">
                              <ListItemText
                                primary={this.state.rubbishTypes[key]}
                              />
                              <ListItemSecondaryAction>
                                <IconButton
                                  onClick={() =>
                                    this.removeRubbishType(
                                      key,
                                      this.state.rubbishTypes[key],
                                    )
                                  }
                                  edge="end"
                                  aria-label="delete"
                                  size="large">
                                  <DeleteIcon color="error" />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
              <Card className={classes.gridContainer}>
                <CardHeader title="Subscribers"></CardHeader>
                <CardContent>
                  <Grid container>
                    <Grid item md={10} xs={10}>
                      <FormControl variant="outlined" className={classes.selectControl}>
                        <InputLabel id="newTeamMemberIDLabel">
                          Add Subscriber...
                        </InputLabel>
                        <Select
                          fullWidth
                          value={this.state.newTeamMemberID || ''}
                          name="newTeamMemberID"
                          label="Add Team Member..."
                          id="newTeamMemberID"
                          onChange={this.onSelectType}
                        >
                          <MenuItem key={0} value="">
                            Select Member...
                          </MenuItem>
                          {this.state.teamMembers.map((key) => {
                            return (
                              <MenuItem key={key.userID} value={key.userID}>
                                {key.firstName} {key.lastName}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={2} xs={2}>
                      <IconButton onClick={this.addSubscriber} aria-label="settings" size="large">
                        <AddCircleIcon fontSize="large" color="primary" />
                      </IconButton>
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <List>
                        {this.state.subscribers.map((key) => {
                          return (
                            <ListItem
                              key={key['qrCodeSubscriberID']}
                              alignItems="flex-start"
                            >
                              <ListItemText
                                primary={
                                  key['firstName'] + ' ' + key['lastName']
                                }
                              />
                              <ListItemSecondaryAction>
                                <IconButton
                                  onClick={() =>
                                    this.removeSubscriber(
                                      key['qrCodeSubscriberID'],
                                      key['firstName'] +
                                        ' ' +
                                        key['lastName'],
                                    )
                                  }
                                  edge="end"
                                  aria-label="delete"
                                  size="large">
                                  <DeleteIcon color="error" />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </TabPanel>
            <TabPanel value={tabValue} index={4}>
              <Card className={classes.gridContainer}>
                <CardHeader
                  title="Duplicate"
                  subheader="This will create a new QR Code with the same properties as this QR Code."
                ></CardHeader>
                <CardContent>
                  <Grid container>
                    <Grid item md={10} xs={10}>
                      <TextField
                        fullWidth
                        label="New Code Name (Optional)"
                        margin="dense"
                        name="newCodeName"
                        variant="outlined"
                        value={this.state.newCodeName || ''}
                        onChange={this.handleChange}
                      />
                    </Grid>

                    <Grid item md={2} xs={2}>
                      <Button
                        onClick={this.cloneCode}
                        variant="contained"
                        style={{ marginTop: '10px', marginLeft: '5px' }}
                        color="primary"
                      >
                        Duplicate
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardHeader
                  title="Transfer"
                  subheader="This will move all the properties and subscribers of this QR Code to an Inactive QR Code. Use this if a QR Code was damaged and you are replacing it with a new sticker."
                ></CardHeader>
                <CardContent>
                  <Grid container>
                    <Grid item md={10} xs={10}>
                      <FormControl variant="outlined" className={classes.selectControl}>
                        <Select
                          fullWidth
                          value={this.state.copyToCode || ''}
                          name="copyToCode"
                          label="Select Inactive Code..."
                          id="copyToCode"
                          onChange={this.onSelectType}
                        >
                          <MenuItem key={0} value="">
                            Clone to Codes must be inactive...
                          </MenuItem>
                          {this.state.inactiveCodes.map((key) => {
                            return (
                              <MenuItem key={key.codeID} value={key.codeID}>
                                {key.identifier}: {key.description}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={2} xs={2}></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </TabPanel>
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.saveRight}
              color="primary"
              variant="contained"
              type="submit"
              onClick={this.updateFormValues}
              disabled={this.state.buttonLoading}
            >
              {this.state.savingSpinner ? 'Saving...' : 'Save Details'}
            </Button>

            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>;
    }
  }
}

export default Store.withStore(withStyles(styles)(EditCode));
