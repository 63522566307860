import { auth } from '../services/firebase';
import config from '../services/config';

export function signup(email, password) {
  return auth().createUserWithEmailAndPassword(email, password);
}

export function signin(email, password) {
  return auth().signInWithEmailAndPassword(email, password);
}

export function resetPassword(email) {
  return auth().sendPasswordResetEmail(email);
}

export function signInWithGoogle() {
  const provider = new auth.GoogleAuthProvider();
  return auth().signInWithPopup(provider);
}

export function signInWithFacebook() {
  const provider = new auth.FacebookAuthProvider();
  provider.addScope('email');
  provider.addScope('name');
  return auth().signInWithPopup(provider);
}
export function signInWithApple() {
  const provider = new auth.OAuthProvider('apple.com');
  return auth().signInWithPopup(provider);
}

export function logout() {
  return auth().signOut();
}

export function sendSignInLink(email) {
  return auth().sendSignInLinkToEmail(email, {
    url: config.rubbishUrl + '/login?email=' + email,
    handleCodeInApp: true,
  });
}
export const linkGoogleAccount = () => {
  // const auth = auth();
  const user = auth().currentUser;
  // const provider = new auth().GoogleAuthProvider();
  const provider = new auth.GoogleAuthProvider();

  user
    .linkWithPopup(provider)
    .then((result) => {
      console.log(result);
      // The Google account is now linked with the Firebase user.
      // You can access the Google user info from result.user
    })
    .catch((error) => {
      console.log(error);
      // Handle errors here.
    });
};
export const linkGithubAccount = () => {
  // const auth = auth();
  const user = auth().currentUser;
  // const provider = new auth().GoogleAuthProvider();
  const provider = new auth.GithubAuthProvider();

  user
    .linkWithPopup(provider)
    .then((result) => {
      console.log(result);
      // The Google account is now linked with the Firebase user.
      // You can access the Google user info from result.user
    })
    .catch((error) => {
      console.log(error);
      // Handle errors here.
    });
};
